import React from 'react'
import { Image, Platform, StyleSheet, View } from 'react-native'
import { Button, Text } from 'react-native-paper'
import { Link } from '../Router'
import { getImageUrl } from '../utils/image'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  notFound: {
    fontSize: 32,
    margin: 32,
    textAlign: 'center',
  },
  image: {
    width: 200,
    height: 200,
  },
})

const NotFound: React.FC = () => (
  <View style={styles.container}>
    <Image
      style={styles.image}
      source={
        Platform.OS === 'web'
          ? { uri: getImageUrl('static/images/404.png') }
          : require('../../../web/public/static/images/404.png')
      }
    />
    <Text style={styles.notFound}>Page Not Found</Text>
    <Link to="/">
      <Button mode="contained">Go Home</Button>
    </Link>
  </View>
)

export default NotFound
