import { PRIMARY_COLOR, SECONDARY_COLOR } from './colors'
import { configureFonts, DefaultTheme } from 'react-native-paper'

const sharedFontConfig = {
  regular: {
    fontFamily: 'Noto Sans JP',
    fontWeight: '400' as '400',
  },
  medium: {
    fontFamily: 'Noto Sans JP',
    fontWeight: '500' as '500',
  },
  light: {
    fontFamily: 'Noto Sans JP',
    fontWeight: '300' as '300',
  },
  thin: {
    fontFamily: 'Noto Sans JP',
    fontWeight: '300' as '300',
  },
}
const fontConfig = {
  android: sharedFontConfig,
  ios: sharedFontConfig,
  web: sharedFontConfig,
}

export const theme = {
  ...DefaultTheme,
  roundness: 4,
  colors: {
    ...DefaultTheme.colors,
    primary: PRIMARY_COLOR,
    accent: SECONDARY_COLOR,
  },
  fonts: configureFonts(fontConfig),
}
