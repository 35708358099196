import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import padStart from 'lodash/padStart'
import sortBy from 'lodash/sortBy'
import React, { useState } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { Avatar, Button, Text, Title } from 'react-native-paper'
import { stringifyPlacing } from '@binary-owls/sdk-node'

import { Placing } from '../types'
import { calculateImportance, isBigEvent, isBiggestEvent } from '../common/placings'
import { compactName, getPlacingName, getPlacingPoints } from '../common/stats'
import { getPlacingTimestamp, getPlacingYear } from '../utils/date'
import { Divider } from '../Divider'
import { BRACKET_TYPES, VIDEOGAME_ACRONYMS } from 'encyclopedia'

interface Props {
  placings?: Placing[]
  showVideogame?: boolean
}

const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
  },
  table: {
    width: '100%',
    marginTop: 12,
    marginBottom: 40,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    marginVertical: 10,
    marginHorizontal: 10,
  },
  event: {
    flex: 3,
    marginHorizontal: 4,
  },
  cell: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    marginHorizontal: 2,
  },
  cellRightJustified: {
    justifyContent: 'flex-end',
  },
  trophy: {
    backgroundColor: 'transparent',
  },
  emphasized: {
    color: '#000',
    fontWeight: 'bold',
  },
  yearButtons: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
})

const LARGE_EVENT_THRESHOLD = 100

function isSignificantEvent(item: Placing) {
  const { entrantCount, eventType } = item
  return isBigEvent(eventType) || isBiggestEvent(eventType) || (entrantCount || 0) >= LARGE_EVENT_THRESHOLD
}

const renderPlacing = (item: Placing, showVideogame: boolean, { showPointsColumn = true, showYear = false }) => {
  const title = getPlacingName(item) || '?'
  const year = getPlacingYear(item) || '????'
  const { id, placing: placement, wins, losses, bracketTypes } = item

  const points = getPlacingPoints(item)
  let trophy
  if ([1, 2, 3].includes(placement) && Platform.OS === 'web') {
    let color
    switch (placement) {
      case 1:
        color = 'gold'
        break
      case 2:
        color = 'silver'
        break
      case 3:
        color = '#b87333'
        break
    }
    trophy = <Avatar.Icon size={24} icon="star" style={styles.trophy} color={color} />
  }

  // Every pool must be RR to show W-L-D, because of cases like https://challonge.com/communities/ALUS2019
  const result =
    bracketTypes?.every(bt => bt && [BRACKET_TYPES.roundRobin, BRACKET_TYPES.swiss].includes(bt)) && (wins || losses)
      ? `${wins}-${losses}`
      : stringifyPlacing(placement)

  const compactTitle = compactName(title)
  return (
    <React.Fragment key={id}>
      <View style={styles.row}>
        <View style={styles.event}>
          <Text numberOfLines={3} style={[isSignificantEvent(item) ? styles.emphasized : null]}>
            {compactTitle}
          </Text>
        </View>
        {showPointsColumn && (
          <View style={[styles.cell, styles.cellRightJustified]}>
            <Text>{points === 0 ? '' : points}</Text>
          </View>
        )}
        {showVideogame && (
          <View style={[styles.cell, styles.cellRightJustified]}>
            <Text>{VIDEOGAME_ACRONYMS[item.videogameSlug]}</Text>
          </View>
        )}
        <View style={[styles.cell, styles.cellRightJustified]}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {trophy}
            <Text>{result}</Text>
          </View>
        </View>
        {showYear && (
          <View style={[styles.cell, styles.cellRightJustified]}>
            <Text>{padStart(`${year}`, 4)}</Text>
          </View>
        )}
      </View>
      <Divider />
    </React.Fragment>
  )
}

// Sort descending by date
const sortPlacings = (placings: Placing[]) => sortBy(placings, item => -(getPlacingTimestamp(item) || 0))

const MAX_SHOWN = 4

interface YearlyPlacings {
  heading: string
  items: Placing[]
  year?: string | number
}

// TODO deprecate
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getImportantPlacings = (placings: Placing[], limit = MAX_SHOWN) => {
  const important = sortBy(placings, item => -calculateImportance(item))
  return sortPlacings(important.slice(0, limit))
}

const filterPlacings = (placings: Placing[], limit = MAX_SHOWN) => {
  if (placings.length > limit) {
    // Filter out people who went 0-2, because it may be a DQ
    const filtered = placings.filter(({ entrantCount, placing, bracketTypes }) => {
      if (bracketTypes?.some(bt => bt && [BRACKET_TYPES.roundRobin, BRACKET_TYPES.swiss].includes(bt))) {
        return true
      }
      if (!entrantCount) {
        return true
      }
      if (entrantCount > 32) {
        return placing < entrantCount * 0.76
      }
      return true
    })
    if (filtered.length > limit) {
      return filtered
    }
  }
  return placings
}

const renderYearlyPlacings = (items: Placing[], showVideogame: boolean, showYear = false) => {
  if (items && items.length) {
    const showPointsColumn = items.some(item => !!getPlacingPoints(item))
    return (
      <View style={styles.table}>
        {items.map(item => renderPlacing(item, showVideogame, { showPointsColumn, showYear }))}
      </View>
    )
  }
}

const Placings: React.FC<Props> = ({ placings, showVideogame = false }) => {
  const [selected, setSelected] = useState(0)

  if (!Array.isArray(placings)) {
    return null
  }

  const allPlacings = filterPlacings(placings)
  let placingGroups: YearlyPlacings[] = []
  let showYear = false

  if (placings.length <= MAX_SHOWN * 2) {
    // If there are few placings, just show them together
    showYear = true
    placingGroups = [
      {
        heading: 'Placings',
        items: sortPlacings(allPlacings),
      },
    ]
  } else {
    const groups = groupBy(allPlacings, getPlacingYear)
    placingGroups = map(groups, (group, year) => {
      return {
        heading: `${year} Placings`,
        items: sortPlacings(group),
        year,
      }
    }).reverse()
  }

  const tabButtons = placingGroups.length > 1 && (
    <View style={styles.yearButtons}>
      {placingGroups.map(({ year }, index) => (
        <Button
          key={`year-button-${year}`}
          mode={index === selected ? 'contained' : 'text'}
          onPress={() => setSelected(index)}
        >
          {index === 0 ? year : `'${`${year || ''}`.slice(2)}`}
        </Button>
      ))}
    </View>
  )

  if (!tabButtons) {
    showYear = true
  }

  const selectedGroup = placingGroups[selected]

  return (
    <View style={{ marginTop: 20 }}>
      <Title style={styles.title}>Events</Title>
      {tabButtons}
      {renderYearlyPlacings(selectedGroup.items, showVideogame, showYear)}
    </View>
  )
}
export default Placings
