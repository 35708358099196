import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { Card } from 'react-native-paper'
import { Heading } from '../Heading'
import { ButtonLink } from '../Link/ButtonLink'

const styles = StyleSheet.create({
  faqCard: {
    alignSelf: 'center',
    width: '90%',
    maxWidth: 1000,
    marginTop: 50,
    marginBottom: 10,
  },
  callToAction: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  new: {
    color: 'green',
    fontWeight: 'bold',
    fontSize: 20,
    marginHorizontal: 10,
  },
})

export const Newsletter: React.FC = () => {
  return (
    <Card elevation={0} style={styles.faqCard}>
      <Card.Content>
        <Heading subheading="We sometimes update you about new things">Email Updates</Heading>
        <View style={styles.callToAction}>
          <ButtonLink
            url="https://e4a15c35.sibforms.com/serve/MUIEAG19fTg-eqIrHo6utd5JQU9sKjpG1mbRtOZ99eBkV7bjxXJrW4vKL6oDF_aYquaZbDfktUWOIoNbg_zvywV1j4-5fhQ2drfwrorWhkFPylVErrPL_cSIuBg4TCPtEFy1cy64_O4M61Ygw1DIVVRyR6l3M439puX_wyHC2zLr2Y49-1fOWeq97nbxldxuml897ohitE3ivTtt"
            mode="contained"
          >
            Get Updates
          </ButtonLink>
        </View>
      </Card.Content>
    </Card>
  )
}
