import { useApolloClient, useQuery } from '@apollo/react-hooks'

import { GET_CURRENT_USER } from '../Users/gql'
import { removeStored, setStored } from './clientStorage'
import { GetCurrentUser, GetCurrentUser_currentUser } from '../Users/__generated__/GetCurrentUser'

const USER_KEY = 'user'

export type CurrentUserProps = Omit<GetCurrentUser_currentUser, '__typename'>

export const loadUser = () => {
  try {
    const data = localStorage.getItem(USER_KEY)
    if (data) {
      return {
        currentUser: JSON.parse(data),
      }
    }
  } catch {
    // nothing
  }
  return null
}

export default function useCurrentUser() {
  const client = useApolloClient()

  const { data } = useQuery<GetCurrentUser>(GET_CURRENT_USER, {
    errorPolicy: 'ignore',
    onError() {
      // Top level query should catch if user is not logged in
    },
  })

  return {
    me: data?.currentUser || null,
    isAdmin: data?.currentUser?.roles?.includes('admin') ?? false,
    isDev: data?.currentUser?.roles?.includes('dev') ?? false,
    clearUser: () => {
      removeStored(USER_KEY)
      // TODO clear other user-specific data
      client.writeQuery<GetCurrentUser>({
        query: GET_CURRENT_USER,
        data: {
          currentUser: null,
        },
      })
    },
    setUser: (currentUserProps: CurrentUserProps) => {
      setStored(USER_KEY, JSON.stringify(currentUserProps))
      client.writeQuery<GetCurrentUser>({
        query: GET_CURRENT_USER,
        data: {
          currentUser: {
            ...currentUserProps,
            __typename: 'User',
          },
        },
      })
    },
  }
}
