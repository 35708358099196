import { getOperationName } from 'apollo-link'
import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { Button, Colors, IconButton, Modal, Portal, Text } from 'react-native-paper'
import { useMutation } from '@apollo/react-hooks'
import { GET_PLAYER, GET_PLAYERS, STITCH_PLAYERS, UNSTITCH_PLAYER } from './gql'
import { StitchPlayers, StitchPlayersVariables } from './__generated__/StitchPlayers'
import { setDevelopmentMode } from '../dev/devMode'
import { UnstitchPlayer, UnstitchPlayerVariables } from './__generated__/UnstitchPlayer'
import useCurrentUser from '../state/useCurrentUser'
import { useHistory } from '../Router'
import { useNotification } from '../Notifications/useNotification'
import { NotificationMessage } from '../Notifications/NotificationMessage'

interface Props {
  players?: string[]
}

const styles = StyleSheet.create({
  toolbar: { justifyContent: 'flex-start', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' },
  button: {
    margin: 5,
  },
  helpModal: {
    backgroundColor: 'white',
    padding: 20,
    margin: 30,
  },
  modalText: {
    marginBottom: 10,
  },
  bold: {
    fontWeight: 'bold',
  },
})

export const AdminToolbar: React.FC<Props> = props => {
  const { isAdmin } = useCurrentUser()
  if (isAdmin) {
    return <InnerComponent {...props} />
  }
  return null
}

const InnerComponent: React.FC<Props> = ({ players = [] }) => {
  const history = useHistory()
  const { notification, setError, setNotification } = useNotification()
  const onCompleted = () => {
    setNotification(
      'Updated. Changes should complete under 1 min for admins, but can require 1 day for most users.',
      'success',
    )
  }
  const [stitch, { loading: stitching }] = useMutation<StitchPlayers, StitchPlayersVariables>(STITCH_PLAYERS, {
    errorPolicy: 'none',
    awaitRefetchQueries: true,
    // @ts-ignore
    refetchQueries: [getOperationName(GET_PLAYER), getOperationName(GET_PLAYERS)],
    onCompleted,
    onError(err) {
      setError(err)
    },
  })
  const [unstitch, { loading: unstitching }] = useMutation<UnstitchPlayer, UnstitchPlayerVariables>(UNSTITCH_PLAYER, {
    errorPolicy: 'none',
    awaitRefetchQueries: true,
    // @ts-ignore
    refetchQueries: [getOperationName(GET_PLAYER), getOperationName(GET_PLAYERS)],
    onCompleted,
    onError(err) {
      setError(err)
    },
  })

  const onStitch = () => {
    setDevelopmentMode()
    if (players?.length !== 2) {
      setError('You need to pick 2 players')
    } else {
      stitch({
        variables: {
          players: players.map(p1 => ({ slug: p1 })),
        },
      }).catch((err: any) => {
        setError(err)
      })
    }
  }
  const onUnstitch = () => {
    setDevelopmentMode()
    if (players?.length !== 1) {
      setError('You can only unstitch 1 player at a time')
    } else {
      unstitch({
        variables: {
          compositeId: { slug: players[0] },
        },
      }).catch((err: any) => {
        setError(err)
      })
    }
  }

  const [visible, setVisible] = React.useState(false)
  const showHelp = () => setVisible(true)
  const hideHelp = () => setVisible(false)

  return (
    <View>
      <Portal>
        <Modal visible={visible} onDismiss={hideHelp} contentContainerStyle={styles.helpModal}>
          <Text style={styles.modalText}>
            Sometimes a player can get duplicated, because they have multiple profiles across websites like
            SmashGG/Capcom Pro Tour/etc. We can use these admin buttons to fix them:
          </Text>
          <Text>
            <Text style={styles.bold}>Find Duplicates</Text> - go to a special webpage that lets you find duplicates for
            a player
          </Text>
          <Text>
            <Text style={styles.bold}>Combine Players</Text> - select two duplicated players in both the P1 and P2
            sides, then press this button to combine them together. When it succeeds, refresh the page.
          </Text>
          <Text>
            <Text style={styles.bold}>Split Player</Text> - if you made a mistake and combined the wrong players, use
            this button to split up a combined player.
          </Text>
        </Modal>
      </Portal>
      <View style={styles.toolbar}>
        <Text>Admin Controls: </Text>
        <Button
          onPress={() => history.push(`/duplicates/${players?.[0]}`)}
          mode="contained"
          disabled={players?.length !== 1}
        >
          Find Duplicates
        </Button>
        <Button
          color={Colors.orange900}
          loading={stitching}
          style={styles.button}
          onPress={onStitch}
          mode="contained"
          disabled={players?.length !== 2 || stitching}
        >
          Combine Players
        </Button>
        <Button
          color={Colors.red900}
          loading={unstitching}
          style={styles.button}
          onPress={onUnstitch}
          mode="contained"
          disabled={players?.length !== 1 || unstitching}
        >
          Split Player
        </Button>
        <IconButton icon="help-circle-outline" color={Colors.blue700} size={25} onPress={() => showHelp()} />
      </View>
      <NotificationMessage {...notification} />
    </View>
  )
}
