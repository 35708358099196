import React, { useState } from 'react'
import { StyleSheet } from 'react-native'
import { Avatar, Button, Card, TextInput, Text } from 'react-native-paper'

import * as LoginTypes from './__generated__/LogIn'
import { getImageUrl } from '../utils/image'
import Loading from '../Loading'
import { useMutation } from '@apollo/react-hooks'
import { SOCIAL_MEDIA_INTEGRATIONS } from '../common/socialMedia'
import LoginButton from './LoginButton'
import { GET_LOGGED_IN_USER, LOG_IN_USER } from './gql'
import { useNetwork } from '../state/useNetwork'
import useCurrentUser from '../state/useCurrentUser'
import { APP_NAME } from '../config'
import { PRIMARY_COLOR } from '../styles/colors'
import GlobalError from '../Notifications/GlobalError'

const styles = StyleSheet.create({
  icon: {
    width: 28,
    textAlign: 'center',
  },
  loginContainer: {
    flex: 1,
    maxWidth: 350,
    minHeight: 300,
  },
  loginWrapper: {
    alignItems: 'center',
  },
  header: {
    color: PRIMARY_COLOR,
    fontSize: 30,
    fontWeight: 'bold',
    margin: 10,
  },
  input: {
    alignSelf: 'stretch',
    margin: 10,
  },
  logo: {
    marginBottom: 20,
  },
  button: {
    alignSelf: 'stretch',
    margin: 10,
  },
})

const LoginForm = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { wrapRequest } = useNetwork()
  const { setUser } = useCurrentUser()
  const [login, { loading }] = useMutation<LoginTypes.LogIn, LoginTypes.LogInVariables>(LOG_IN_USER, {
    errorPolicy: 'none',
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_LOGGED_IN_USER }],
    onCompleted({ login }) {
      if (login) {
        setUser({
          email: login.email,
          username: login.username,
          twitterImage: login.twitterImage,
          twitterName: login.twitterName,
          twitterScreenName: login.twitterScreenName,
          roles: login.roles,
        })
      }
    },
  })

  const onSubmit = wrapRequest(() => login({ variables: { email, password } }))

  return (
    <Card elevation={5} style={styles.loginContainer}>
      <Card.Content style={styles.loginWrapper}>
        <Avatar.Image
          style={styles.logo}
          size={128}
          source={{ uri: getImageUrl('static/images/logo/logo-circle.svg') }}
        />
        <Text style={styles.header}>{APP_NAME}</Text>

        {SOCIAL_MEDIA_INTEGRATIONS.map(socialMedia => (
          <LoginButton disabled={loading} key={`login-button-${socialMedia}`} socialMedia={socialMedia} />
        ))}

        {false && (
          <>
            <Text style={[styles.header, { fontSize: 20 }]}>or</Text>

            <TextInput
              autoCapitalize="none"
              autoCompleteType="off"
              autoCorrect={false}
              label="Email or Username"
              mode="outlined"
              style={styles.input}
              autoFocus={!email}
              onChangeText={setEmail}
              onSubmitEditing={onSubmit}
              value={email}
            />
            <TextInput
              autoCapitalize="none"
              autoCompleteType="off"
              autoCorrect={false}
              secureTextEntry
              label="Password"
              mode="outlined"
              style={styles.input}
              autoFocus={!!email}
              onChangeText={setPassword}
              onSubmitEditing={onSubmit}
              value={password}
            />
            <Button disabled={!email || !password || loading} mode="contained" style={styles.button} onPress={onSubmit}>
              Log In
            </Button>
          </>
        )}
        <Loading loading={loading} />
        <GlobalError />
      </Card.Content>
    </Card>
  )
}
export default LoginForm
