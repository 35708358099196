import * as React from 'react'
import { StyleSheet } from 'react-native'
import { Text } from 'react-native-paper'

const VERSUS_MARGIN = 16
const VERSUS_FONT_SIZE = 36

const styles = StyleSheet.create({
  versusSymbol: {
    flex: 0,
    alignSelf: 'center',
    fontSize: VERSUS_FONT_SIZE,
    margin: VERSUS_MARGIN,
    textShadowColor: 'rgba(0, 0, 0, 0.75)',
    textShadowOffset: { width: -1, height: 1 },
    textShadowRadius: 4,
  },
})

const VersusSymbol = () => <Text style={styles.versusSymbol}>VS</Text>

export default VersusSymbol
