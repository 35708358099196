import { getPlayerName } from 'encyclopedia'
import * as React from 'react'
import fuzzysort from 'fuzzysort'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'
import { StyleSheet, View } from 'react-native'
import { Avatar, List, Searchbar, Text } from 'react-native-paper'
import { PlayerSearchItem } from '../types'
import { sharedStyles } from '../styles'
import { getPlayerId, getPortraitSource, stringifyPlayerHandle } from '../common/players'
import Loading from '../Loading'
import Pagination from '../Pagination'
import { Divider } from '../Divider'
import { NotificationMessage } from '../Notifications/NotificationMessage'
import { ContactUsLink } from '../About/ContactUsLink'

export interface SearchPlayer {
  (id: string, searchString: string): void
}

const { useState } = React

const styles = StyleSheet.create({
  listItem: {
    minWidth: 96,
  },
  listItemRight: {
    alignSelf: 'center',
  },
  listItemRightText: {
    textAlign: 'right',
  },
})

interface Props {
  setPlayer: SearchPlayer
  initialSearch: string
  searchItems: PlayerSearchItem[]
  loading: boolean
}

const LIMIT = 30
const PER_PAGE = 10

export const PlayerSearch: React.FC<Props> = ({ initialSearch = '', searchItems, loading, setPlayer }) => {
  const [value, setValue] = useState(initialSearch)

  // TODO for multiple players, search all handles
  let results
  if (value) {
    results = fuzzysort
      .go(value, searchItems, {
        allowTypo: true,
        keys: ['handle', 'name'],
        limit: LIMIT,
        threshold: -200,
      })
      .map(({ obj }) => obj)
  } else {
    results = searchItems
  }
  let shownItems = uniqBy(results, ({ player }) => player)
  const totalPages = Math.ceil(shownItems.length / PER_PAGE)
  const [currentPage, setCurrentPage] = useState(0)
  shownItems = shownItems.slice(currentPage * PER_PAGE, (currentPage + 1) * PER_PAGE)

  const renderItem = (searchItem: PlayerSearchItem, isFirst: boolean) => {
    const { player } = searchItem
    const { sl, hd, nm, img } = player
    const uniqHandles = uniq(hd)
    let tag = sortBy(hd, 'length')[0]
    if (uniqHandles.length > 1 && value) {
      const tagLC = tag.toLowerCase()
      const valueLC = value.toLowerCase()
      const appendix = uniqHandles.filter(handle => {
        const handleLC = handle.toLowerCase()
        if (handleLC === tagLC) {
          return false
        }
        if (value.length > 3 && handleLC.startsWith(valueLC)) {
          return true
        }
        return !tagLC.includes(valueLC) && handleLC.includes(valueLC)
      })?.[0]
      tag += appendix ? ` (${appendix})` : ''
    }
    tag = stringifyPlayerHandle(tag)
    const name = nm && sortBy(nm, 'length')[0]
    const onPress = () => {
      setPlayer(`${getPlayerId(player)}`, value)
    }
    return (
      <React.Fragment key={sl}>
        {!isFirst && <Divider />}
        <List.Item
          onPress={onPress}
          style={{ minWidth: 250 }}
          title={tag}
          description={value && name && tag !== name && !name.includes('Brandon H') && !name.includes('Timothy W') ? getPlayerName(name) : ' '}
          titleStyle={styles.listItem}
          descriptionStyle={styles.listItem}
          left={props => <Avatar.Image source={getPortraitSource(img)} {...props} style={sharedStyles.avatar} />}
          right={() => (
            <View style={styles.listItemRight}>
              <Text style={styles.listItemRightText}>{' ' /*{qualified ? '' : rank}*/}</Text>
              <Text style={[sharedStyles.textFaded, styles.listItemRightText]}>
                {' ' /*{qualified ? 'Qualified' : pts}*/}
              </Text>
            </View>
          )}
        />
      </React.Fragment>
    )
  }

  const onSearch = (text: string) => {
    setCurrentPage(0)
    setValue(text)
  }

  let message
  if (!shownItems.length) {
    message = (
      <NotificationMessage center>
        If a player is not found, just <ContactUsLink>contact us</ContactUsLink> so we can add them
      </NotificationMessage>
    )
  } else if (1 < shownItems.length && shownItems.length < PER_PAGE) {
    message = (
      <NotificationMessage center>
        Problems? <ContactUsLink>Contact us</ContactUsLink> to fix.
      </NotificationMessage>
    )
  }

  return (
    <Loading loading={loading}>
      <Searchbar
        autoCapitalize="none"
        autoCompleteType="off"
        autoCorrect={false}
        placeholder="Enter player name ..."
        onChangeText={onSearch}
        value={value}
      />
      <Pagination page={currentPage} numberOfPages={totalPages} onPageChange={setCurrentPage} />
      <List.Section>{shownItems.map((item, index) => renderItem(item, index === 0))}</List.Section>
      {message}
    </Loading>
  )
}
