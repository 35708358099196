import flatten from 'lodash/flatten'
import { useQuery } from '@apollo/react-hooks'
import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-paper'

import Loading from '../Loading'
import { PlayerCard } from './PlayerCard'
import { TwitterTextLink } from '../Social/TwitterTextLink'
import Placings from './Placings'
import { GetPlayer, GetPlayerVariables } from './__generated__/GetPlayer'
import { GET_PLAYER } from './gql'
import { useNotification } from '../Notifications/useNotification'
import { NotificationMessage } from '../Notifications/NotificationMessage'
import { defaultsFavoringLast } from '@binary-owls/sdk-node'
import { qualify } from '../common/players'
import { Placing, Standing } from '../types'

const styles = StyleSheet.create({
  containerItem: {
    flex: 1,
    flexDirection: 'column',
    flexWrap: 'nowrap',
    marginHorizontal: 16,
  },
  text: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 'bold',
  },
})

interface PlayerItem {
  slug: string
  handle: string
  name: string | null
  team: string | null
  portrait: string | null
  country: string | null
  standings: Standing[]
  placings: Placing[]
}

interface PlayerProfileProps {
  id: string
  data?: PlayerItem[] | null
  loading: boolean
  videogameSlug?: string
}

export const PlayerProfile: React.FC<PlayerProfileProps> = ({ id, loading, videogameSlug, data }) => {
  let embed
  if (loading) {
    embed = <Loading />
  } else if (data?.length) {
    const player = defaultsFavoringLast(data, qualify)
    embed = <PlayerCard {...player} videogameSlug={videogameSlug} />
  } else {
    embed = (
      <View style={{ alignSelf: 'center' }}>
        <Text style={styles.text}>Could not find player</Text>
        <Text style={styles.text}>
          Help us get better{' '}
          <TwitterTextLink text={`There was an issue finding player: ${id}`}>
            by reporting this to @FGCombo
          </TwitterTextLink>
        </Text>
      </View>
    )
  }

  return (
    <View>
      <View style={styles.containerItem}>{embed}</View>
      <View style={styles.containerItem}>
        {data && <Placings showVideogame={!videogameSlug} placings={flatten(data.map(p1 => p1.placings))} />}
      </View>
    </View>
  )
}

interface QueriedPlayerProfileProps {
  id: string
  videogameSlug?: string
}

// this will run the query
export const QueriedPlayerProfile: React.FC<QueriedPlayerProfileProps> = ({ id, videogameSlug }) => {
  const { notification, setError } = useNotification()

  const { data, loading } = useQuery<GetPlayer, GetPlayerVariables>(GET_PLAYER, {
    skip: !id,
    variables: {
      id,
      videogameSlug,
    },
    errorPolicy: 'none',
    onError(err) {
      setError(err)
    },
  })

  return (
    <View>
      <NotificationMessage {...notification} />
      <PlayerProfile id={id} loading={loading} data={data?.playerWithStats} videogameSlug={videogameSlug} />
    </View>
  )
}
