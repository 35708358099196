import { VIDEOGAME_NAMES } from 'encyclopedia'
import omitBy from 'lodash/omitBy'
import * as React from 'react'
import { View } from 'react-native'
import { RouteComponentProps } from 'react-router'
import { formatUrlQuery, parseUrlQuery } from '@binary-owls/sdk-node'

import { useMediaQueries } from '../utils/useScreen'
import { AdminVersus, NonadminVersus } from './Versus'
import { AdminToolbar } from './AdminToolbar'
import useCurrentUser from '../state/useCurrentUser'
import { SplashMessage } from '../SplashMessage'
import { Heading2 } from '../Heading2'

const buildQuery = (p1: string, p2: string) => {
  const query = omitBy({ p1, p2 }, (item: any) => !item)
  const search = formatUrlQuery(query)
  return search && `?${search}`
}

interface Params {
  videogameAcronym?: string
}

export const PlayerVersusPlayer: React.FC<RouteComponentProps<Params>> = ({ history, location, match }) => {
  const { isAdmin } = useCurrentUser()

  const { p1, p2 } = parseUrlQuery(location.search)

  const { isSmallScreen } = useMediaQueries()
  const showSecondPlayer = !isSmallScreen

  const { pathname, search, state } = history.location
  const navigate = (one: string, two: string, newState?: { search1?: string; search2?: string }) => {
    history.replace(`${pathname}${search}`, { ...state, ...newState })
    history.push({ search: buildQuery(one, two) })
  }
  // @ts-ignore
  const { search1, search2 } = history.location.state || { search1: '', search2: '' }

  const videogameSlug = match.params.videogameAcronym || ''
  const title = VIDEOGAME_NAMES[videogameSlug]
  if (!title) {
    return (
      <SplashMessage tweet={`There was an issue finding game ${videogameSlug}.`}>Could not find game</SplashMessage>
    )
  }

  const props = {
    navigate,
    p1,
    p2,
    search1,
    search2,
    showSecondPlayer,
    videogameSlug,
  }

  return (
    <View>
      <Heading2>{title}</Heading2>
      {isAdmin && <AdminToolbar players={[p1 as string, p2 as string].filter(p => p)} />}
      {isAdmin ? (
        <AdminVersus key="AdminVersus" {...props} />
      ) : (
        <NonadminVersus key={`NonadminVersus-${videogameSlug}`} {...props} />
      )}
    </View>
  )
}
