import { Text } from 'react-native-paper'
import * as React from 'react'
import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  new: {
    color: 'green',
    fontWeight: 'bold',
    fontSize: 20,
    marginHorizontal: 10,
  },
  small: {
    fontSize: 15,
  },
})

interface Props {
  small?: boolean
}

export const New: React.FC<Props> = ({ small }) => {
  return <Text style={[styles.new, small && styles.small]}>NEW!</Text>
}
