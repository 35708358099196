import sortBy from 'lodash/sortBy'
import * as React from 'react'
import { View } from 'react-native'
import { Text } from 'react-native-paper'
import { useQuery } from '@apollo/react-hooks'
import { GET_CHARACTERS } from './gql'
import { GetCharacters } from './__generated__/GetCharacters'
import Loading from '../Loading'
import { useNetwork } from '../state/useNetwork'
import GlobalError from '../Notifications/GlobalError'

export const Characters: React.FC = () => {
  const { handleNetworkError, handleNetworkSuccess } = useNetwork()
  const { data, error, loading } = useQuery<GetCharacters>(GET_CHARACTERS, {
    onError(err) {
      handleNetworkError(err)
    },
    onCompleted(data) {
      handleNetworkSuccess()
    },
  })

  if (loading) {
    return <Loading />
  }

  return (
    <View>
      <GlobalError error={error} />
      {sortBy(data?.characters, ['videogameName', 'name'])?.map(character => (
        <Text>
          {`${character.videogameName} \t`}
          <Text style={{ fontWeight: 'bold' }}>{character.name}</Text>
        </Text>
      ))}
    </View>
  )
}
