import { StyleSheet } from 'react-native'
import { BACKGROUND_COLOR, FADED, GREEN, PRIMARY_COLOR, RED } from './colors'

export const SAND_WIDTH = 100
export const SAND_HEIGHT = Math.round(SAND_WIDTH / 1.618)
export const GRAVEL_WIDTH = 200
export const GRAVEL_HEIGHT = Math.round(GRAVEL_WIDTH / 1.618)
export const PEBBLE_WIDTH = 300
export const PEBBLE_HEIGHT = Math.round(PEBBLE_WIDTH / 1.618)
export const COBBLE_WIDTH = 480
export const COBBLE_HEIGHT = Math.round(COBBLE_WIDTH / 1.618)
export const BOULDER_WIDTH = 640
export const BOULDER_HEIGHT = Math.round(BOULDER_WIDTH / 1.618)
export const MOBILE_LANDSCAPE_WIDTH = 644
export const MOBILE_PORTRAIT_WIDTH = 384
export const PLAYER_PROFILE_WIDTH = 300


export const sharedStyles = StyleSheet.create({
  avatar: {
    backgroundColor: 'transparent',
  },
  header: {
    fontSize: 32,
    alignSelf: 'center',
    marginTop: 4,
    marginBottom: 16,
  },
  notice: {
    width: '100%',
    alignSelf: 'center',
    fontSize: 16,
    margin: 4,
  },
  spaceVertically: {
    marginVertical: 48,
  },
  textFaded: {
    color: FADED,
  },
  textLogo: {
    color: PRIMARY_COLOR,
    backgroundColor: BACKGROUND_COLOR,
  },
  textSubtitle: {
    fontSize: 18,
    margin: 8,
    textAlign: 'center',
  },
  textFeature: {
    fontSize: 36,
    fontWeight: 'bold',
    margin: 8,
    textAlign: 'center',
  },
  textSection: {
    fontSize: 24,
    fontWeight: 'bold',
    marginTop: 16,
    marginBottom: 10,
    textAlign: 'center',
  },
  textError: {
    color: RED,
  },
  textSuccess: {
    color: GREEN,
  }
})
