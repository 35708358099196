import React from 'react'
import { Button } from 'react-native-paper'
import { getColor, getTitle } from '../common/socialMedia'
import { loginWithSocialMedia } from '../api/auth'
import { useNetwork } from '../state/useNetwork'
import { CONTRAST_COLOR } from '../styles/colors'

interface Props {
  disabled?: boolean
  socialMedia: string
}

const LoginButton: React.FC<Props> = ({ socialMedia, ...rest }) => {
  const { wrapRequest } = useNetwork()
  const onPress = wrapRequest(() => loginWithSocialMedia(socialMedia))
  return (
    <Button
      {...rest}
      color={CONTRAST_COLOR}
      onPress={onPress}
      style={{ backgroundColor: getColor(socialMedia), margin: 10, alignSelf: 'stretch' }}
    >
      Log in with {getTitle(socialMedia)}
    </Button>
  )
}

export default LoginButton
