export const SERIES_TYPES = {
  local: 'local',
  major: 'major',
  online: 'online',
}

export const SERIES_TYPES_HUMANIZED = Object.freeze({
  [SERIES_TYPES.local]: 'Local',
  [SERIES_TYPES.major]: 'Major',
  [SERIES_TYPES.online]: 'Online',
})

export const humanizeSeriesType = (seriesType: string) => SERIES_TYPES_HUMANIZED[seriesType]
