import React from 'react'
import { ApolloError } from 'apollo-client'

type NotificationType = 'error' | 'info' | 'success'
type NotificationContent = string | Error | ApolloError

interface NotificationState {
  type: NotificationType
  children: NotificationContent
}

export const useNotification = () => {
  const [notification, setNotification] = React.useState<NotificationState>({
    type: 'info',
    children: '',
  })

  return {
    notification,
    setNotification: (children: NotificationContent, type: NotificationType = 'info') =>
      setNotification({ children, type }),
    setError: (children: NotificationContent) => setNotification({ children, type: 'error' }),
  }
}
