import { useMutation, useQuery } from '@apollo/react-hooks'
import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { Avatar, Button, Text } from 'react-native-paper'

import * as LogOutTypes from './__generated__/LogOut'
import { GET_CURRENT_USER, GET_LOGGED_IN_USER, LOG_OUT_USER } from './gql'
import Loading from '../Loading'
import useCurrentUser from '../state/useCurrentUser'
import { GetCurrentUser } from './__generated__/GetCurrentUser'
import ErrorDisplay from '../Notifications/ErrorDisplay'
import { useHistory } from '../Router'
import { PRIMARY_COLOR } from '../styles/colors'
import AdminLinks from './AdminLinks'

const USER_PROFILE_HEADER_SIZE = 20

const useInputChanges = (initialValue?: string | null) => {
  const [value, setValue] = useState(initialValue ?? '')
  return {
    value,
    onChangeText: setValue,
  }
}

export const UserProfile = () => {
  const history = useHistory()
  const { clearUser } = useCurrentUser()
  const { data, loading: loadingQuery } = useQuery<GetCurrentUser>(GET_CURRENT_USER, {
    onError() {
      // Top level query should catch if user is not logged in
    },
  })
  const [logout, { error, loading: loadingLogout }] = useMutation<LogOutTypes.LogOut>(LOG_OUT_USER, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_CURRENT_USER }, { query: GET_LOGGED_IN_USER }],
    onCompleted() {
      clearUser()
      history.replace('/')
    },
  })

  const emailInputChanges = useInputChanges(data?.currentUser?.email)
  const passwordInputChanges = useInputChanges() // TODO

  const onLogout = () => {
    return logout().catch(console.error)
  }
  const loading = loadingLogout || loadingQuery
  if (loading) {
    return <Loading splash />
  }

  if (!data?.currentUser) {
    return null
  }

  const Footer = () => (
    <View style={styles.actions}>
      {/*<Button*/}
      {/*  style={styles.action}*/}
      {/*  disabled={loading || [emailInputChanges.value, passwordInputChanges.value].some(v => !v)}*/}
      {/*  appearance="filled"*/}
      {/*  onPress={() => alert('TODO')}*/}
      {/*>*/}
      {/*  Save*/}
      {/*</Button>*/}
      <Button style={styles.action} disabled={loading} mode="contained" onPress={onLogout}>
        Log Out
      </Button>
      <ErrorDisplay error={error} />
    </View>
  )
  const name = data?.currentUser?.twitterName || data?.currentUser?.username
  const uri = data?.currentUser?.twitterImage

  return (
    <View style={styles.container}>
      <View style={styles.card}>
        <View style={styles.header}>
          {uri && (
            <Avatar.Image
              size={1.5 * USER_PROFILE_HEADER_SIZE}
              source={{ uri }}
              style={{
                backgroundColor: 'transparent',
              }}
            />
          )}
          <Text style={styles.headerText}>{name}</Text>
        </View>

        <AdminLinks />

        <View>
          {renderRow('Role', data?.currentUser?.roles?.join(', '), PRIMARY_COLOR)}
          {renderRow('Email', emailInputChanges.value)}
          {renderRow('Twitter', data?.currentUser?.twitterScreenName ? `@${data.currentUser.twitterScreenName}` : '')}
          {renderRow('Logged in with', data?.currentUser?.twitterScreenName && 'Twitter')}
          {/*<View style={styles.row}>*/}
          {/*  <Text style={{ width: 150, fontWeight: 'bold' }}>Plan</Text>*/}
          {/*  <View style={styles.value}>*/}
          {/*    <Text style={{ marginRight: 10 }}>$0 / month</Text>*/}
          {/*  </View>*/}
          {/*</View>*/}
          {/*<Input disabled={true} label="email" style={styles.input} {...emailInputChanges} />*/}
          {/*<Input disabled={true} label="password" style={styles.input} {...passwordInputChanges} secureTextEntry />*/}
        </View>
        <View style={styles.footer}>
          <Footer />
        </View>
      </View>
    </View>
  )
}

const renderRow = (key: string, value: string | undefined | null, color = '') =>
  value ? (
    <View style={styles.row}>
      <Text style={{ flex: 1, width: 150, fontWeight: 'bold' }}>{key}</Text>
      <Text style={color ? { borderWidth: 2, borderColor: color, borderRadius: 5, padding: 2 } : {}}>{value}</Text>
    </View>
  ) : null

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  value: {
    flex: 1,
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  card: {
    height: 250,
    justifyContent: 'flex-start',
  },
  footer: {
    marginVertical: 20,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 20,
  },
  headerText: {
    marginLeft: 5,
    fontSize: USER_PROFILE_HEADER_SIZE,
    fontWeight: 'normal',
  },
  row: {
    marginVertical: 10,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  input: {
    marginVertical: 10,
  },
  actions: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 20,
  },
  action: {
    minWidth: 100,
    marginRight: 20,
  },
})
