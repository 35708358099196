import * as React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

interface Props {
  style?: StyleProp<ViewStyle>
}

export const Divider: React.FC<Props> = ({ style }) => {
  return (
    <View
      style={[
        {
          height: 1,
          backgroundColor: '#CED0CE',
          marginHorizontal: 10,
        },
        style,
      ]}
    />
  )
}
