import React, { useCallback } from 'react'
import { Alert, Linking } from 'react-native'
import { Button } from 'react-native-paper'

interface Props {
  url: string
  mode?: 'text' | 'outlined' | 'contained'
}

export const ButtonLink: React.FC<Props> = ({ url, children, ...rest }) => {
  const [disabled, setDisabled] = React.useState(false)

  const handlePress = useCallback(async () => {
    setDisabled(true)
    // Checking if the link is supported for links with custom URL scheme.
    const supported = await Linking.canOpenURL(url)

    if (supported) {
      // Opening the link with some app, if the URL scheme is "http" the web link should be opened
      // by some browser in the mobile
      await Linking.openURL(url)
    } else {
      Alert.alert(`Don't know how to open this URL: ${url}`)
    }
    setDisabled(false)
  }, [url])

  return (
    <Button onPress={handlePress} {...rest} disabled={disabled}>
      {children}
    </Button>
  )
}
