import gql from 'graphql-tag'

export const GET_CHARACTERS = gql`
  query GetCharacters {
    characters {
      name
      videogameName
    }
  }
`
