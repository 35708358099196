import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useBackButton } from './utils/useBackButton'
import { Route, Switch } from './Router'
import LoginPage from './Users/LoginPage'
import Stats from './Stats'
import NotFound from './Navigation/404'
import { Characters } from './Characters/Characters'
import { UserProfile } from './Users/UserProfile'
import { Newsletter } from './About/Newsletter'
import { NewsletterConfirmation } from './About/NewsletterConfirmation'
import { NewsletterUnsubscribed } from './About/NewsletterUnsubscribed'
import { ContactUs } from './About/ContactUs'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: 10,
    paddingHorizontal: 10,
    paddingBottom: 100,
  },
})

export default () => {
  useBackButton()
  return (
    <View style={styles.container}>
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/characters" component={Characters} />
        <Route exact path="/user" component={UserProfile} />
        <Route exact path="/newsletter/opted-in" component={NewsletterConfirmation} />
        <Route exact path="/newsletter/opted-out" component={NewsletterUnsubscribed} />
        <Route exact path="/newsletter" component={Newsletter} />
        <Route exact path="/contact" component={ContactUs} />
        <Route>
          <Stats />
        </Route>
        <Route component={NotFound} />
      </Switch>
    </View>
  )
}
