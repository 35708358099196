import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { Heading } from '../Heading'
import { Text } from 'react-native-paper'
import { PRIMARY_COLOR } from '../styles/colors'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  text: {
    marginTop: 20,
    fontSize: 15,
    fontWeight: '500',
  },
  emailText: {
    color: PRIMARY_COLOR,
  },
})

export const NewsletterConfirmation: React.FC = () => {
  return (
    <View style={styles.container}>
      <Heading>You're confirmed for email updates</Heading>
      <Text style={styles.text}>
        Please add <Text style={styles.emailText}>hello@fgcombo.com</Text> to your email contacts
      </Text>
      <Text style={styles.text}>Check your Promotions/Spam folder if you don't see an email in a few minutes</Text>
    </View>
  )
}
