import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-paper'

const styles = StyleSheet.create({
  heading: {
    fontSize: 30,
    fontWeight: '500',
    marginTop: 30,
    marginBottom: 10,
    textAlign: 'center',
  },
  subheading: {
    textAlign: 'center',
    fontSize: 15,
    fontWeight: '500',
    marginTop: 0,
    marginBottom: 20,
  },
})

interface Props {
  subheading?: string
}

export const Heading: React.FC<Props> = ({ children = '', subheading }) => {
  return (
    <View>
      <Text style={styles.heading}>{children}</Text>
      {subheading && <Text style={styles.subheading}>{subheading}</Text>}
    </View>
  )
}
