import React from 'react'
import { Text } from 'react-native'
import { useInterval } from './useInterval'

interface Props {
  children?: any
  fontSize?: number
  loading?: boolean
  maxCount?: number
  timeout?: number
}

export const LoadingEllipses: React.FC<Props> = ({
  children,
  loading = true,
  fontSize = 20,
  maxCount = 3,
  timeout = 500,
}) => {
  const [count, setCount] = React.useState<number>(1)

  useInterval(() => {
    let nextCount = count + 1
    if (nextCount > maxCount) {
      nextCount = 1
    }
    setCount(nextCount)
  }, timeout)

  if (!loading) {
    if (children) {
      return children
    }
    return null
  }

  let displayText = ''
  for (let i = 0; i < count; i++) {
    displayText += '.'
  }

  return <Text style={{ fontSize }}>{displayText}</Text>
}
