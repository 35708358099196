import moment from 'moment'
import first from 'lodash/first'
import last from 'lodash/last'
import { Placing, Standing } from '../types'

const DATE_FORMAT = 'MM/DD/YYYY'

const parseDate = (input?: string | null) => {
  const m1 = moment(input ?? '', DATE_FORMAT)
  if (m1.isValid()) {
    return m1
  }
  const m2 = moment(input ?? '')
  if (m2.isValid()) {
    return m2
  }
}

export const getPlacingStartAt = ({ startDate, date }: Placing) => {
  if (date) {
    const m1 = moment(first(date.split(' - ')), DATE_FORMAT)
    if (m1.isValid()) {
      return m1.valueOf()
    }
  }
  const m3 = moment(startDate || '', DATE_FORMAT)
  if (m3.isValid()) {
    return m3.valueOf()
  }
  return null
}

// NOTE: startDate < bracketDate, date is a range
export const getPlacingTimestamp = ({
  occurredAt,
  openedAt,
  bracketDate,
  date,
  startDate,
  year,
  tournamentStartAt,
}: Placing) => {
  const dateStrings = [occurredAt]
  let dateString
  if (date) {
    dateString = last(date.split(' - '))
    dateStrings.push(dateString)
  }
  dateStrings.push(bracketDate, startDate)
  if (tournamentStartAt && tournamentStartAt !== '0001-01-01T00:00:00Z') {
    dateStrings.push(tournamentStartAt)
  }
  dateStrings.push(openedAt)

  for (const dateStr of dateStrings) {
    const result = parseDate(dateStr)
    if (result) {
      return result.valueOf()
    }
  }

  return year
}

export const getPlacingYear = (item: Placing) => {
  const dateStrings = [
    // In order of reliable accuracy
    item.occurredAt,
    item.openedAt,
    item.tournamentStartAt,
    item.tournamentEndAt,
    item.bracketDate,
    item.startDate,
  ]

  for (const dateStr of dateStrings) {
    const result = parseDate(dateStr)
    if (result) {
      return result.year()
    }
  }
  return item.year
}

export const getStandingYear = (item: Standing) => {
  const m1 = moment(item.leagueEndAt || item.year || '')
  return m1.isValid() ? m1.year() : item.year
}
