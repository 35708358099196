import React from 'react'
import { ApolloError } from 'apollo-client'
import { StyleSheet, View } from 'react-native'
import { Colors, Text } from 'react-native-paper'
import ErrorDisplay from './ErrorDisplay'

const styles = StyleSheet.create({
  error: {
    color: Colors.red700,
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  info: {
    color: Colors.blue700,
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  success: {
    color: Colors.green700,
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  default: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  centerText: {
    textAlign: 'center',
  },
})

interface Props {
  children?: any
  type?: 'error' | 'info' | 'success' | 'default'
  center?: boolean
}

export const NotificationMessage: React.FC<Props> = ({ children, type = 'default', center }) => {
  if (children) {
    if (type === 'error') {
      return <ErrorDisplay error={children} />
    }
    return <Text style={[styles[type], center && styles.centerText]}>{children}</Text>
  }
  return null
}
