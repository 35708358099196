import { useApolloClient } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import { removeStored, setStored } from './clientStorage'
import { GetCurrentVideogame } from './__generated__/GetCurrentVideogame'

const GET_CURRENT_VIDEOGAME = gql`
  query GetCurrentVideogame {
    currentVideogame @client
  }
`

const VIDEOGAME_KEY = 'videogame'

export const loadVideogame = () => {
  return {
    currentVideogame: localStorage.getItem(VIDEOGAME_KEY),
  }
}

export default function useCurrentVideogame() {
  const client = useApolloClient()
  const data = client.readQuery<GetCurrentVideogame>({ query: GET_CURRENT_VIDEOGAME })

  const videogame = data?.currentVideogame || ''
  return {
    clearVideogame: () => {
      removeStored(VIDEOGAME_KEY)
      client.writeQuery<GetCurrentVideogame>({
        query: GET_CURRENT_VIDEOGAME,
        data: {
          currentVideogame: null,
        },
      })
    },
    videogameSlug: videogame,
    setVideogame: (videogameSlug: string) => {
      setStored(VIDEOGAME_KEY, videogameSlug)
      client.writeQuery<GetCurrentVideogame>({
        query: GET_CURRENT_VIDEOGAME,
        data: {
          currentVideogame: videogameSlug,
        },
      })
    },
  }
}
