// TODO remove unused social media
import {
  FACEBOOK_PRIMARY_COLOR,
  INSTAGRAM_PRIMARY_COLOR,
  LINKEDIN_PRIMARY_COLOR,
  TWITTER_PRIMARY_COLOR,
} from '../styles/colors'

export const SOCIAL_MEDIA = {
  facebook: 'facebook',
  instagram: 'instagram',
  linkedin: 'linkedin',
  twitter: 'twitter',
}

export const SOCIAL_MEDIA_INTEGRATIONS = [SOCIAL_MEDIA.twitter]

const TITLES = {
  [SOCIAL_MEDIA.facebook]: 'Facebook',
  [SOCIAL_MEDIA.instagram]: 'Instagram',
  [SOCIAL_MEDIA.linkedin]: 'LinkedIn',
  [SOCIAL_MEDIA.twitter]: 'Twitter',
}
export const getTitle = (type: string) => TITLES[type]

const COLORS = {
  [SOCIAL_MEDIA.facebook]: FACEBOOK_PRIMARY_COLOR,
  [SOCIAL_MEDIA.instagram]: INSTAGRAM_PRIMARY_COLOR,
  [SOCIAL_MEDIA.linkedin]: LINKEDIN_PRIMARY_COLOR,
  [SOCIAL_MEDIA.twitter]: TWITTER_PRIMARY_COLOR,
}
export const getColor = (type: string) => COLORS[type]
