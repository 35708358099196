import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { Avatar, Card, Text } from 'react-native-paper'
import { PEBBLE_WIDTH } from '../styles'
import { images } from './images'
import { VIDEOGAME_NAMES } from 'encyclopedia'
import { New } from '../AlertMessage/New'
import { isNewlyAddedVideogame } from '../newRelease'

interface Props {
  small?: boolean
  videogame: string
}

const styles = StyleSheet.create({
  avatar: {
    backgroundColor: 'transparent',
  },
  card: {
    width: PEBBLE_WIDTH,
  },
  cardSmall: {
    margin: 5,
    width: PEBBLE_WIDTH / 2,
  },
  container: {
    maxWidth: '100%',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 5,
  },
  containerSmall: {
    maxWidth: '100%',
    alignItems: 'center',
    margin: 5,
  },
  title: {
    fontSize: 25,
    fontWeight: '500',
    margin: 10,
    textAlign: 'center',
  },
  titleSmall: {
    height: 50,
    fontSize: 18,
    fontWeight: '500',
    margin: 5,
    textAlign: 'center',
  },
})

export const Videogame: React.FC<Props> = ({ children, small, videogame }) => {
  return (
    <Card elevation={8} style={small ? styles.cardSmall : styles.card}>
      <Card.Content style={small ? styles.containerSmall : styles.container}>
        <Avatar.Image size={small ? 50 : 100} source={images[videogame]} style={styles.avatar} />
        <Text style={small ? styles.titleSmall : styles.title}>
          {VIDEOGAME_NAMES[videogame]}
          {isNewlyAddedVideogame(videogame) && <New />}
        </Text>
        <View>{children}</View>
      </Card.Content>
    </Card>
  )
}
