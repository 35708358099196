import React from 'react'
import { TextLink } from '../Link/TextLink'
import { constructTweetUrl } from './Twitter'

interface Props {
  at?: string
  text?: string
  url?: string
}

export const TwitterTextLink: React.FC<Props> = ({ at = '@FGCombo', text = '', url, children }) => {
  const constructedUrl = constructTweetUrl({
    text: `${at ? `${at} ` : ''}${text}`,
    urlToShare: url,
  })
  return <TextLink url={constructedUrl}>{children ?? '@FGCombo'}</TextLink>
}
