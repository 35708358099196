import React from 'react'
import { TextLink } from '../Link/TextLink'

export const TwitterDirectMessageLink: React.FC = ({ children }) => {
  return (
    <TextLink url="https://twitter.com/messages/compose?recipient_id=1408104356">{children ?? '@FGCombo'}</TextLink>
  )
}

export const DiscordInviteLink: React.FC = ({ children }) => {
  return <TextLink url="https://discord.gg/tgHhPg2">{children ?? 'FGCombo Discord'}</TextLink>
}
