import { PointsEarned } from '../types'

export const SF_REGIONS = {
  global: 'Global',
  ao: 'Asia/Oceania',
  eu: 'Europe',
  na: 'North America',
  la: 'Latin America',
}

export const isGlobalRegion = ({ region }: PointsEarned) => region === SF_REGIONS.global || !region
