import { VIDEOGAME_SLUGS } from 'encyclopedia'
import { getImageUrl } from '../utils/image'

export const images = {
  [VIDEOGAME_SLUGS.dbfz]: { uri: getImageUrl('static/images/games/dbfz.png') },
  [VIDEOGAME_SLUGS.gbvs]: { uri: getImageUrl('static/images/games/gbvs.png') },
  [VIDEOGAME_SLUGS.ggs]: { uri: getImageUrl('static/images/games/ggs.jpg') },
  [VIDEOGAME_SLUGS.mk11]: { uri: getImageUrl('static/images/games/mk11.jpg') },
  [VIDEOGAME_SLUGS.sf4]: { uri: getImageUrl('static/images/games/USF4.jpg') },
  [VIDEOGAME_SLUGS.sf5]: { uri: getImageUrl('static/images/games/sfv.png') },
  [VIDEOGAME_SLUGS.sc6]: { uri: getImageUrl('static/images/games/SC6.jpg') },
  [VIDEOGAME_SLUGS.sg]: { uri: getImageUrl('static/images/games/sg.png') },
  [VIDEOGAME_SLUGS.t7]: { uri: getImageUrl('static/images/games/T7.jpg') },
}
