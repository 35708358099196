// TODO default CPT portrait is currently 404
const DEFAULT_CPT_PORTRAIT_URLS = ['https://capcomprotour.com/wp-content/uploads/2014/02/capcom-pt-logo.png']
const DEFAULT_TWT_PORTRAIT_URLS = [
  'https://tekkenworldtour.com/wp-content/uploads/2019/03/logo.png',
  'https://tekkenworldtour.com/wp-content/uploads/2020/09/TOC_EU_West-winner-360px-150x150.png',
  'https://tekkenworldtour.com/wp-content/uploads/2020/07/TOC_EU_North-500px-150x150.png',
  'https://tekkenworldtour.com/wp-content/uploads/2020/07/TOC_Japan-500px-150x150.png',
  'https://tekkenworldtour.com/wp-content/uploads/2020/07/TOC_Korea-500px-150x150.png',
  'https://tekkenworldtour.com/wp-content/uploads/2020/07/TOC_Philippines-500px-150x150.png',
]
const DEFAULT_PORTRAIT_URLS = [...DEFAULT_CPT_PORTRAIT_URLS, ...DEFAULT_TWT_PORTRAIT_URLS]
export const URL_ENDING_NAME_REGEX = /\/([^/]+\.\w{3,4})$/
export const isDefaultPortrait = (portrait: string) => DEFAULT_PORTRAIT_URLS.includes(portrait)

const PORTRAIT_META = [
  {
    pathSlug: 'cpt',
    isMatch(portrait: string) {
      // Prefer official website portrait, unless it is a dummy portrait from the website
      return !DEFAULT_CPT_PORTRAIT_URLS.includes(portrait) && /capcomprotour.com/.test(portrait)
    },
  },
  {
    pathSlug: 'twt',
    isMatch(portrait: string) {
      // Prefer official website portrait, unless it is a dummy portrait from the website
      return !DEFAULT_TWT_PORTRAIT_URLS.includes(portrait) && /tekkenworldtour.com/.test(portrait)
    },
  },
]

export const matchPortraitFile = (portrait: string) => {
  for (const { pathSlug, isMatch } of PORTRAIT_META) {
    if (isMatch(portrait)) {
      const matches = portrait.match(URL_ENDING_NAME_REGEX)
      // TODO does this work for TWT?
      if (matches) {
        const fileName = matches[1]
        return {
          fileName,
          pathSlug,
        }
      }
    }
  }
  return null
}

export const isFavorablePortrait = (portrait?: string | null) => {
  // Higher priority if the portrait is from one of the official pro tours
  if (!portrait) {
    return false
  }
  const match = matchPortraitFile(portrait)
  return !!match
}

export const getPlayerName = (name: string | null) => {
  let displayName = name
  if (name) {
    const tokens = name.trim().split(/\s+/)
    if (tokens.length > 0) {
      // make last name an initial
      if (tokens.length > 1 && tokens[tokens.length - 1].length > 2) {
        tokens[tokens.length - 1] = tokens[tokens.length - 1][0]
      }
      displayName = tokens.join(' ')
    }
  }
  return displayName
}
