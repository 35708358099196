import { getApi, getRequest } from './restful'

export const loginWithSocialMedia = (socialMedia: string) => {
  return fetch(getApi(`/auth/${socialMedia}`), {
    redirect: 'manual',
    headers: {
      Authorization: 'Token TODO',
    },
  }).then(response => {
    if (response.type === 'opaqueredirect') {
      // Since the XHR request doesn't automatically redirect, we manually redirect on the 302
      window.location.href = `${response.url}?returnTo=${window.location.origin}`
    } else {
      throw Error('Failed to contact server')
    }
    return response
  })
}

export const logout = () => getRequest('/auth/logout')
