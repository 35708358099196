import { useEffect } from 'react'
import { BackHandler } from 'react-native'
import { useHistory } from '../Router'

export const useBackButton = () => {
  const history = useHistory()

  useEffect(() => {
    // handle Android back button
    const onHardwareBackPress = () => {
      // @ts-ignore
      if (history.index === 0) {
        // On react-router-native, history.index tracks current route
        return false
      }
      // Back press caught, navigating back ...
      history.goBack()
      return true
    }
    BackHandler.addEventListener('hardwareBackPress', onHardwareBackPress)
    return () => {
      BackHandler.removeEventListener('hardwareBackPress', onHardwareBackPress)
    }
  }, [])
}
