// web-only
// export const clear = localStorage.clear.bind(localStorage)
export const getStored = (key: string) => localStorage.getItem(key)
export const getStoredBoolean = (key: string) => {
  const value = getStored(key)
  if (value) {
    const number = parseInt(value)
    if (!isNaN(number)) {
      return !!number
    }
  }
  return !!value
}
export const removeStored = (key: string) => localStorage.removeItem(key)
export const setStored = (key: string, value: string) => localStorage.setItem(key, value)
