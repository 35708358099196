import * as React from 'react'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import { Text } from 'react-native-paper'
import { StyleProp, StyleSheet, TextStyle, View } from 'react-native'

import { HeadToHeadSets_headToHead } from './__generated__/HeadToHeadSets'
import { Player } from '../types'
import { sharedStyles } from '../styles'
import { compactName } from '../common/stats'
import { FADED_GREY } from '../styles/colors'
import { BRACKET_TYPES } from 'encyclopedia'

const H2H_SETS_SHOWN = 7

const styles = StyleSheet.create({
  surface: {
    width: '100%',
    padding: 5,
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomWidth: 2,
    borderColor: FADED_GREY,
    marginBottom: 5,
  },
  scores: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
  },
  textScore: {
    fontSize: 24,
    marginHorizontal: 8,
  },
  textTournament: {
    fontSize: 18,
    marginVertical: 4,
  },
  textDescription: {
    fontSize: 14,
    marginVertical: 4,
  },
})

interface SetProps {
  leftScore: string | number
  rightScore: string | number
  leftStyles?: StyleProp<TextStyle>
  rightStyles?: StyleProp<TextStyle>
}

const SetScore: React.FC<SetProps> = ({ leftScore, rightScore, leftStyles, rightStyles }) => {
  return (
    <View style={styles.scores}>
      <Text style={[styles.textScore, leftStyles]}>{leftScore}</Text>
      <Text style={styles.textScore}>-</Text>
      <Text style={[styles.textScore, rightStyles]}>{rightScore}</Text>
    </View>
  )
}

interface Props {
  sets?: HeadToHeadSets_headToHead[]
  showVideogame?: boolean
  leftPlayer: Player[]
}

const REGEX_GRAND_FINAL = /Grand Final/i
const isGrandFinal = (set: HeadToHeadSets_headToHead) => set?.name && REGEX_GRAND_FINAL.test(set.name)

const renderScores = (set: HeadToHeadSets_headToHead, leftIds: string[]) => {
  let { playerOneId, playerOneScore, playerTwoId, playerTwoScore, winnerId } = set
  let style1, style2
  let score1: string | number = '?'
  let score2: string | number = '?'

  let winnerKnown = true
  if (winnerId === playerOneId) {
    style1 = sharedStyles.textSuccess
    style2 = sharedStyles.textError
    score1 = 'W'
    score2 = 'L'
  } else if (winnerId === playerTwoId) {
    style1 = sharedStyles.textError
    style2 = sharedStyles.textSuccess
    score1 = 'L'
    score2 = 'W'
  } else {
    winnerKnown = false
  }

  if (typeof playerOneScore === 'number' || typeof playerTwoScore === 'number') {
    playerOneScore = playerOneScore ?? 0
    playerTwoScore = playerTwoScore ?? 0

    if (winnerKnown) {
      // Prefer winner instead of score, because scores can contradict the winner e.g. Dieminion vs KBrad winner finals https://nextlevel.challonge.com/NLBC22AE2012
    } else if (playerOneScore > playerTwoScore) {
      style1 = sharedStyles.textSuccess
      style2 = sharedStyles.textError
      score1 = 'W'
      score2 = 'L'
    } else if (playerOneScore < playerTwoScore) {
      style1 = sharedStyles.textError
      style2 = sharedStyles.textSuccess
      score1 = 'L'
      score2 = 'W'
    }
    // NOTE: Conditional used here because scores may be erroneous, e.g. 0 to 0, 0 to -1
    if ((playerOneScore >= 0 && playerTwoScore > 0) || (playerOneScore > 0 && playerTwoScore >= 0)) {
      // If scores are best of 1, e.g. 1-0, then there's usually a problem
      const bothBinary = [playerOneScore, playerTwoScore].every(sc => sc < 2)
      score1 = bothBinary ? (playerOneScore === 1 ? 'W' : 'L') : playerOneScore
      score2 = bothBinary ? (playerTwoScore === 1 ? 'W' : 'L') : playerTwoScore
    } else if (playerOneScore < 0) {
      score1 = 'L'
    } else if (playerTwoScore < 0) {
      score2 = 'L'
    }
  }

  let leftScore, rightScore
  let leftStyles, rightStyles
  if (playerOneId && leftIds.indexOf(playerOneId) !== -1) {
    // p1 is on the left
    leftScore = score1
    leftStyles = style1
    rightScore = score2
    rightStyles = style2
  } else {
    // p2 is on the left
    leftScore = score2
    leftStyles = style2
    rightScore = score1
    rightStyles = style1
  }
  const props = {
    leftScore,
    leftStyles,
    rightScore,
    rightStyles,
  }
  return <SetScore {...props} />
}

const YEAR_REGEX = /\s?\b(\d{4})\b/
const displayTournamentName = (set: HeadToHeadSets_headToHead) => {
  const { endAt, startAt, tournamentName, eventName } = set
  let displayName = compactName(tournamentName || eventName) || 'Tournament ???'

  const matches = displayName.match(YEAR_REGEX)
  if (matches) {
    displayName = `${displayName.replace(YEAR_REGEX, '')} ${matches[1]}`
  } else {
    displayName += ` ${moment(endAt).year() || moment(startAt).year() || ''}`
  }
  return displayName
}

const PHASE_NAME_REGEXES = ['final', 'top', 'group', 'pool'].map(str => new RegExp(str, 'i'))
const isGoodPhaseName = (phaseName?: string | null) =>
  phaseName && PHASE_NAME_REGEXES.some(regex => regex.test(phaseName))

const loserOrWinnerRound = (set: HeadToHeadSets_headToHead) => {
  const { round, bracketType } = set
  if (bracketType !== BRACKET_TYPES.doubleElimination) {
    return ''
  }
  const result = typeof round === 'number' && round < 0 ? 'Losers' : 'Winners'
  return ` ${result} `
}

const HeadToHead: React.FC<Props> = ({ sets, showVideogame, leftPlayer }) => {
  if (!sets || !sets.length) {
    return null
  }

  const leftIds = Array.isArray(leftPlayer) ? leftPlayer.map(({ id }) => id) : [get(sets, '[0].id')]
  const setsByEvent = Object.values(groupBy(sets, 'eventId'))

  const setGroups = sortBy(setsByEvent, items => items[0].endAt || items[0].startAt)
    .reverse()
    .map(setsInEvent => {
      const items = []
      let contiguousSets: HeadToHeadSets_headToHead[] = []

      for (const set of sortBy(setsInEvent, s => s.round).reverse()) {
        if (!contiguousSets.length) {
          contiguousSets.push(set)
        } else {
          const set0 = contiguousSets[0]

          // Compare sets to see if they go together, i.e. if 2 sets are in a grand finals
          if (
            set.round === set0.round ||
            (isGrandFinal(set) && isGrandFinal(set0) && set.tournamentName === set0.tournamentName)
          ) {
            contiguousSets.push(set)
            // TODO: Sanity check: only case where there are grouped sets is a grand finals reset
          } else {
            // Push the previous, but instantiate
            items.push(sortBy(contiguousSets, s => s.createdAt).reverse())
            contiguousSets = [set]
          }
        }
      }
      // TODO instead of using createdAt, we may need to use an actual set.playOrder

      if (contiguousSets.length) {
        items.push(sortBy(contiguousSets, s => s.createdAt).reverse())
      }

      return items
    })

  return (
    <>
      {setGroups.slice(0, H2H_SETS_SHOWN).map((listOfEventSets, i) => {
        return (
          <View key={`set-group-${i}`} style={styles.surface}>
            <Text style={styles.textTournament}>{displayTournamentName(listOfEventSets[0][0])}</Text>
            {listOfEventSets.map(contiguousSets => {
              const set = contiguousSets[0]
              const set2 = contiguousSets[1]
              const scores = renderScores(set, leftIds)
              let scores2
              if (set2) {
                scores2 = renderScores(set2, leftIds)
              }
              const { id, name, numSeeds, groupCount, phaseName, bracketType, eventSize, round } = set
              let description
              if (set2) {
                description = 'Grand Finals'
              } else if (isGoodPhaseName(phaseName)) {
                description = phaseName
              } else if (name && /round\s/i.test(name)) {
                description = name
              } else if (numSeeds) {
                if (
                  // SmashGG pools have "**** Finals" even though it wouldn't be top 8, therefore we count entrants
                  numSeeds <= 64 &&
                  groupCount === 1 &&
                  name &&
                  /(grand|winner|loser)?.{0,3}(semi-?)?final/i.test(name)
                ) {
                  description = name
                } else if (eventSize && numSeeds <= eventSize / 2) {
                  // numSeeds is the total players left by this set's phase
                  description = `Top ${numSeeds}`
                  description += loserOrWinnerRound(set)
                } else if (
                  bracketType &&
                  [BRACKET_TYPES.doubleElimination, BRACKET_TYPES.singleElimination].includes(bracketType)
                ) {
                  description = `${loserOrWinnerRound(set)}Round ${round}`
                }
              } else if (phaseName) {
                if (/^phase/i.test(phaseName)) {
                  description = groupCount === 1 ? 'Main Bracket' : 'Pools'
                } else {
                  description = phaseName
                }
              }

              return (
                <React.Fragment key={id}>
                  <Text style={styles.textDescription}>{description}</Text>
                  {scores}
                  {scores2}
                </React.Fragment>
              )
            })}
          </View>
        )
      })}
    </>
  )
}

export default HeadToHead
