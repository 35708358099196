import React from 'react'
import { Image, Platform, StyleSheet, View } from 'react-native'
import { Text } from 'react-native-paper'

import { getImageUrl } from './utils/image'
import { isInMaintenance } from './config'
import DefaultBody from './AppBody.shared'

const styles = StyleSheet.create({
  text: {
    // textShadowColor: 'rgba(0, 0, 0, 0.75)',
    // textShadowOffset: { width: -1, height: 1 },
    // textShadowRadius: 2,
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 20,
  },
  view: {
    alignItems: 'center',
    width: '100%',
  },
  image: {
    flex: 1,
    height: 452,
    width: '100%',
    maxWidth: 349,
  },
})

export const Body = () => {
  if (isInMaintenance()) {
    return (
      <View style={styles.view}>
        <Text style={styles.text}>Updating. Check back later!</Text>
        <Image
          style={styles.image}
          source={
            Platform.OS === 'web'
              ? { uri: getImageUrl('static/images/hugo.gif') }
              : require('../../web/public/static/images/hugo.gif')
          }
        />
      </View>
    )
  }
  return <DefaultBody />
}
