import React, { useContext } from 'react'
import { withRouter } from 'react-router'

// TODO react-router will support React Hooks in the future

export const RouterContext = React.createContext({
  match: null,
  location: null,
  history: null,
})

export const useRouterContext = () => useContext(RouterContext)

export const RouterContextProvider = ({ children, match, location, history }: any) => (
  <RouterContext.Provider value={{ match, location, history }}>{children}</RouterContext.Provider>
)

export default withRouter(RouterContextProvider)
