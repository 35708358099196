import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { Heading } from '../Heading'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
})

export const NewsletterUnsubscribed: React.FC = () => {
  return (
    <View style={styles.container}>
      <Heading subheading="Just kidding.  Have a great day!">You're Banished to Netherrealm</Heading>
    </View>
  )
}
