import * as React from 'react'
import { View, StyleSheet } from 'react-native'
import { Text } from 'react-native-paper'
import { TwitterTextLink } from './Social/TwitterTextLink'

interface Props {
  children: string
  tweet?: string
}

export const styles = StyleSheet.create({
  splash: {
    width: '100%',
    alignSelf: 'center',
    textAlign: 'center',
  },
  text: {
    fontSize: 20,
    textAlign: 'center',
    padding: 10,
    margin: 10,
  },
  cta: {
    fontSize: 15,
    textAlign: 'center',
    padding: 10,
    margin: 10,
  },
})

export const SplashMessage: React.FC<Props> = ({ children, tweet }) => {
  return (
    <View style={styles.splash}>
      <Text style={styles.text}>{children}</Text>
      <Text style={styles.cta}>
        Please report this issue to <TwitterTextLink text={tweet}>@FGCombo</TwitterTextLink>
      </Text>
    </View>
  )
}
