import * as React from 'react'
import { DataTable } from 'react-native-paper'

interface Props {
  onPageChange(page: number): void
  page: number
  numberOfPages: number
}

const THRESHOLD = 9
const Pagination: React.FC<Props> = props => {
  const { page, numberOfPages } = props
  const humanizedPage = page + 1
  let denominator: string | number = numberOfPages > THRESHOLD ? Math.max(THRESHOLD, humanizedPage) : numberOfPages
  if (denominator < numberOfPages) {
    denominator = `${denominator}+`
  }
  return <DataTable.Pagination {...props} label={`${humanizedPage} / ${denominator}`} />
}

export default Pagination
