import { parseUrlQuery } from '@binary-owls/sdk-node'
import { useEffect } from 'react'
import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { ApolloError, isApolloError } from 'apollo-client'
import gql from 'graphql-tag'

import * as NetworkMessageTypes from './__generated__/NetworkMessage'
import { NetworkMessage } from './__generated__/NetworkMessage'

export const NETWORK_MESSAGE = gql`
  query NetworkMessage {
    lastNetworkMessage @client {
      code
      error
      message
      reason
    }
  }
`

export const interpretErrorMessage = (message: string) => {
  if (message.includes('Failed to fetch')) {
    return 'Network connection failed'
  }
  return message
}

export const useNetwork = () => {
  const client = useApolloClient()
  const { data: lastMessage } = useQuery<NetworkMessageTypes.NetworkMessage>(NETWORK_MESSAGE)
  useEffect(() => {
    const { code, error, message, reason } = parseUrlQuery(window.location.href)
    client.writeQuery({
      query: NETWORK_MESSAGE,
      data: {
        lastNetworkMessage: {
          __typename: 'DisplayMessage',
          code: code ?? null,
          error: error ?? null,
          message: message ?? null,
          reason: reason ?? null,
        },
      },
    })
  }, [window.location.href])

  const handleNetworkSuccess = () => {
    client.writeQuery<NetworkMessage>({
      query: NETWORK_MESSAGE,
      data: {
        lastNetworkMessage: null,
      },
    })
  }

  const handleNetworkError = (err?: ApolloError | Error) => {
    if (!err) {
      return
    }

    let message
    if (isApolloError(err)) {
      if (err.graphQLErrors.length) {
        message = err.graphQLErrors.map(e => e.message).join('. ')
      } else {
        message = err?.networkError?.message ?? 'Network error'
      }
    } else {
      message = err.message
    }

    client.writeQuery<NetworkMessage>({
      query: NETWORK_MESSAGE,
      data: {
        lastNetworkMessage: {
          __typename: 'DisplayMessage',
          code: null,
          error: err.name,
          message: interpretErrorMessage(message),
          reason: null,
        },
      },
    })
  }

  const handleNetworkResponse = (promise: Promise<any>) => {
    return promise.then(handleNetworkSuccess).catch(handleNetworkError)
  }

  const wrapRequest = (request: () => Promise<any>) => {
    return () => {
      return handleNetworkResponse(request())
    }
  }

  return {
    handleNetworkError,
    handleNetworkSuccess,
    lastMessage: lastMessage?.lastNetworkMessage,
    wrapRequest,
  }
}
