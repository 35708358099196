import * as React from 'react'
import { withRouter } from 'react-router'
import { RouteComponentProps } from 'react-router'
import { NavigationBar } from './NavigationBar'

class Navigation extends React.Component<RouteComponentProps> {
  public render() {
    const { history } = this.props
    const props = {
      atHome: history.location.pathname === '/',
      atUserProfile: history.location.pathname === '/user',
      goBack: () => history.goBack(),
      goHome: () => history.push('/'),
      goLogin: () => history.push('/login'),
      goToProfile: () => history.push('/user'),
    }
    return <NavigationBar {...props} />
  }
}

export default withRouter(Navigation)
