import { formatUrlQuery } from '@binary-owls/sdk-node'

interface TwitterUrlParams {
  urlToShare?: string
  viaUser?: string
  text?: string
  hashtags?: string[] // TODO
}

// Example https://twitter.com/intent/tweet?url=https%3A%2F%2Fmedium.com%2F%40kitze%2Fgithub-stars-wont-pay-your-rent-8b348e12baed&via=Dave&text=Hello%20world&hashtags=Hash1%2CHash2

export const TWEET_INTENT_URL = 'https://twitter.com/intent/tweet'

export const constructTweetUrl = (params: TwitterUrlParams) => {
  if (!Object.values(params).length) {
    console.error('Twitter URL cannot be constructed from 0 parameters')
    return ''
  }

  if (params && Object.keys(params).length) {
    const queryString = formatUrlQuery({
      url: params.urlToShare,
      via: params.viaUser,
      text: params.text,
    })
    return `${TWEET_INTENT_URL}?${queryString}`
  }
  return TWEET_INTENT_URL
}
