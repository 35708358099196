import * as React from 'react'
import { StyleSheet } from 'react-native'
import { Text } from 'react-native-paper'
import { Link } from '../Router'
import { TWITTER_PRIMARY_COLOR } from '../styles/colors'

const styles = StyleSheet.create({
  linkText: {
    fontWeight: 'bold',
    color: TWITTER_PRIMARY_COLOR,
  },
})

export const ContactUsLink: React.FC = ({ children }) => {
  return (
    <Link to="/contact">
      <Text style={styles.linkText}>{children || 'send us'}</Text>
    </Link>
  )
}
