import { useEffect, useRef } from 'react'

type IntervalCallback = () => any

// implementation by Dan Abramov https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback: IntervalCallback, delay: number) {
  const savedCallback = useRef<IntervalCallback>()

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current()
      }
    }

    const intervalId = setInterval(tick, delay)
    return () => {
      clearInterval(intervalId)
    }
  }, [delay])
}
