import * as React from 'react'
import { Card, Text } from 'react-native-paper'
import { StyleSheet, View } from 'react-native'

import { DARK_GREEN } from '../styles/colors'
import { HeadToHeadSummary_headToHeadSummary } from './__generated__/HeadToHeadSummary'

const styles = StyleSheet.create({
  card: {
    width: '100%',
    marginBottom: 20,
  },
  surface: {
    width: '100%',
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 5,
  },
  scores: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
  },
  textScore: {
    fontSize: 24,
    marginHorizontal: 8,
  },
  textTournament: {
    fontSize: 18,
    marginVertical: 4,
  },
  textBetterScore: {
    color: DARK_GREEN,
  },
})

interface Props {
  data?: HeadToHeadSummary_headToHeadSummary | null
  showVideogame?: boolean
}

function toPercentage(top: number, bottom: number) {
  return Math.round((top * 100) / bottom)
}

const HeadToHeadSummary: React.FC<Props> = ({ data }) => {
  if (
    typeof data?.totalSets === 'number' &&
    typeof data.player1Wins === 'number' &&
    typeof data.player2Wins === 'number'
  ) {
    if (data.totalSets < 4) {
      return null
    }

    const score1 = toPercentage(data.player1Wins, data.totalSets)
    const score2 = toPercentage(data.player2Wins, data.totalSets)

    return (
      <Card elevation={4} style={styles.card}>
        <View style={styles.surface}>
          <Text style={styles.textTournament}>
            {data.totalSets < 10 ? 'A few' : `${data.totalSets - 1}+`} sets played:
          </Text>
          <View style={styles.scores}>
            <Text style={[styles.textScore, score1 > score2 && styles.textBetterScore]}>{score1}%</Text>
            <Text style={styles.textScore}>-</Text>
            <Text style={[styles.textScore, score2 > score1 && styles.textBetterScore]}>{score2}%</Text>
          </View>
        </View>
      </Card>
    )
  }

  return null
}

export default HeadToHeadSummary
