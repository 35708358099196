import { ApolloProvider } from '@apollo/react-hooks'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Portal, Provider as PaperProvider } from 'react-native-paper'

import { theme } from './styles/theme'
import { Platform, ScrollView } from 'react-native'
import Navigation from './Navigation/Navigation'
import { Router } from './Router'
import { RouterContextProvider } from './Router/context'
import { resolvers } from './state/resolvers'
import { typeDefs } from './state/schema'
import { API_URL, APP_NAME } from './config'
import { loadUser } from './state/useCurrentUser'
import { loadVideogame } from './state/useCurrentVideogame'
import { loadYear } from './state/useCurrentYear'
import { initializeSessionReplay } from './dev/sessionReplay'
import { Body } from './AppBody'

// set up user monitoring
initializeSessionReplay()

///////////////////////////////////////////////////////////////////////////////////////////////////
// setup Apollo client
///////////////////////////////////////////////////////////////////////////////////////////////////
const cache = new InMemoryCache()
const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link: new HttpLink({
    credentials: 'include',
    uri: `${API_URL}/graphql`,
    fetchOptions: {
      credentials: 'include',
    },
  }),
  resolvers,
  typeDefs,
})

const data = ([loadUser, loadVideogame, loadYear] as (() => any)[]).reduce(
  (previousValue, currentValue) => {
    return {
      ...previousValue,
      ...currentValue(),
    }
  },
  {
    lastNetworkMessage: null,
  },
)
cache.writeData({
  data,
})

///////////////////////////////////////////////////////////////////////////////////////////////////
// setup App
///////////////////////////////////////////////////////////////////////////////////////////////////
export default () => {
  return (
    <PaperProvider theme={theme}>
      <ApolloProvider client={client}>
        <Portal.Host>
          {Platform.OS === 'web' ? (
            // need .default on require() below, otherwise it renders a useless [object Module] instead of /static/media/MaterialCommunityIcons.a7629910.ttf https://stackoverflow.com/questions/59070216/webpack-file-loader-outputs-object-module
            <style type="text/css">{`
          @font-face {
            font-family: 'MaterialCommunityIcons';
            src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf').default}) format('truetype');
          }
        `}</style>
          ) : null}
          <RouterContextProvider>
            <ScrollView>
              <Helmet>
                <title>{APP_NAME}</title>
              </Helmet>
              <Router>
                <Navigation />
                <Body />
              </Router>
            </ScrollView>
          </RouterContextProvider>
        </Portal.Host>
      </ApolloProvider>
    </PaperProvider>
  )
}
