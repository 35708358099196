// export const PRIMARY_COLOR = '#9657f6'
export const PRIMARY_COLOR = '#563f46'
export const SECONDARY_COLOR = '#FFF8A6'
export const CONTRAST_COLOR = '#f6f6f6'
export const ERROR_COLOR = '#ba3515'
export const RED = '#ba3515'
export const FADED = 'rgba(0, 0, 0, 0.54)'
export const FADED_GREY = 'rgba(64, 64, 64, 0.3)'
export const BACKGROUND_COLOR = '#F0F1EE'
export const GREEN = '#00a75e'
export const DARK_GREEN = '#005e19'
export const COLOR_PALE_WHITE = '#eeeeee'

// social media colors
export const FACEBOOK_PRIMARY_COLOR = '#4166b1'
export const INSTAGRAM_PRIMARY_COLOR = '#dd2a7b'
export const LINKEDIN_PRIMARY_COLOR = '#0e76a8'
export const TWITTER_PRIMARY_COLOR = '#00acee'


