import { View } from 'react-native'
import { Button, Card, Text } from 'react-native-paper'
import React from 'react'
import { Link } from '../Router'
import useCurrentUser from '../state/useCurrentUser'

export default function AdminLinks() {
  const { isAdmin, isDev } = useCurrentUser()

  if (isAdmin || isDev) {
    return (
      <Card elevation={4} style={{ marginTop: 10, marginBottom: 30 }}>
        <Card.Content style={{ marginBottom: 10 }}>
          <View style={{ alignSelf: 'center' }}>
            <Text style={{ fontSize: 25, margin: 20 }}>Admin Options</Text>
          </View>
          <Link to="/duplicates">
            <Button mode="contained">Manage Duplicates</Button>
          </Link>
        </Card.Content>
      </Card>
    )
  }
  return null
}
