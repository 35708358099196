import LogRocket from 'logrocket'
import { APP_NAME } from '../config'

interface Identity {
  id: string
  name?: string | null
  email?: string | null
  [key: string]: string | null | undefined
}

const ENABLED = window.location.href.indexOf(`${APP_NAME.toLowerCase()}.com`) !== -1

export const initializeSessionReplay = () => {
  if (ENABLED) {
    LogRocket.init('v61b9k/stats')
  }
}

// Let's them know who current user is
export const identify = ({ id, name, email, ...rest }: Identity) => {
  if (ENABLED) {
    LogRocket.identify(id, {
      name: name ?? 'unknown',
      email: email ?? 'unknown',
      // custom vars:
      ...rest,
    })
  }
}
