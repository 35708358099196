import { ApolloError } from 'apollo-client'
import React from 'react'
import { View } from 'react-native'

import ErrorDisplay from './ErrorDisplay'
import ErrorText from './ErrorText'
import { useNetwork } from '../state/useNetwork'

interface Props {
  error?: ApolloError | Error
  marginTop?: number
  marginBottom?: number
}

const GlobalError: React.FC<Props> = ({ error, marginTop = 10, marginBottom = 5 }) => {
  const { lastMessage } = useNetwork()
  let body
  if (error) {
    body = <ErrorDisplay error={error} />
  } else if (lastMessage?.message) {
    let errorText = lastMessage.message
    if (!/error/i.test(errorText)) {
      errorText = `Error: ${errorText}`
    }
    body = <ErrorText>{errorText}</ErrorText>
  } else {
    return null
  }
  return <View style={{ marginTop, marginBottom }}>{body}</View>
}

export default GlobalError
