import { useEffect, useState } from 'react'
import { Dimensions, ScaledSize } from 'react-native'
import { MOBILE_LANDSCAPE_WIDTH, MOBILE_PORTRAIT_WIDTH } from '../styles'

const useScreenWidth = () => {
  const [width, setWidth] = useState(Dimensions.get('window').width)

  useEffect(() => {
    // width is used to determine 1 or 2 player view
    const handleDimensionChange = ({ window }: { window: ScaledSize }) => {
      setWidth(window.width)
    }
    Dimensions.addEventListener('change', handleDimensionChange)
    return () => {
      Dimensions.removeEventListener('change', handleDimensionChange)
    }
  }, [])

  return width
}

export const useMediaQueries = () => {
  const width = useScreenWidth()

  const isTinyScreen = width <= MOBILE_PORTRAIT_WIDTH
  const isLargeScreen = MOBILE_LANDSCAPE_WIDTH < width
  return {
    isTinyScreen,
    isSmallScreen: !isLargeScreen,
    isLargeScreen,
  }
}
