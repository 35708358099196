import { useApolloClient } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import { GetCurrentYear } from './__generated__/GetCurrentYear'

export const GET_CURRENT_YEAR = gql`
  query GetCurrentYear {
    currentYear @client
  }
`

// const YEAR_KEY = 'videogame'

export const loadYear = () => {
  return {
    // TODO get year from backend?
    currentYear: new Date().getFullYear(),
  }
}

export default function useCurrentYear() {
  const client = useApolloClient()
  const data = client.readQuery<GetCurrentYear>({ query: GET_CURRENT_YEAR })

  return {
    currentYear: data?.currentYear,
    // setYear: (year: integer) => {
    //   setItem(YEAR_KEY, year)
    //   client.writeQuery<GetCurrentYear>({
    //     query: GET_CURRENT_YEAR,
    //     data: {
    //       currentYear: year,
    //     },
    //   })
    // },
  }
}
