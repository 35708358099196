import gql from 'graphql-tag'

export const GET_CHARACTERS_PLAYED = gql`
  query GetCharactersPlayed($identifier: String!, $videogameSlug: String) {
    charactersPlayed(identifier: $identifier, videogameSlug: $videogameSlug) {
      name
      playCount
    }
  }
`

export const GET_PLAYERS = gql`
  query GetPlayers($videogameSlug: String) {
    playersRanked(videogameSlug: $videogameSlug) {
      wt
      sl
      hd
      nm
      cp
      img
    }
  }
`

export const PLACING_FRAG = gql`
  fragment PlacingFrag on PlacingLite {
    __typename
    id
    openedAt
    occurredAt
    bracketDate
    date
    startDate
    year
    bracketTypes
    eventName
    eventType
    eventSameId
    placing
    wins
    losses
    pointsEarned {
      points
      region
    }

    entrantCount
    seriesPrefix
    tournamentName
    tournamentStartAt
    tournamentEndAt
    videogameSlug
  }
`
export const STANDING_FRAG = gql`
  fragment StandingFrag on StandingLite {
    __typename
    id
    leagueEndAt
    points
    qualified
    rank
    region
    year
  }
`

export const GET_PLAYER = gql`
  query GetPlayer($id: ID!, $videogameSlug: String) {
    playerWithStats(id: $id, videogameSlug: $videogameSlug) {
      id
      slug
      cptId
      twtId
      team
      handle
      name
      country
      portrait
      placings {
        ...PlacingFrag
      }
      standings {
        ...StandingFrag
      }
    }
  }
  ${PLACING_FRAG}
  ${STANDING_FRAG}
`

export const HEAD_TO_HEAD_SETS = gql`
  query HeadToHeadSets($player1: [ID!], $player2: [ID!], $limit: Int, $videogameSlug: String) {
    headToHead(player1: $player1, player2: $player2, limit: $limit, videogameSlug: $videogameSlug) {
      id
      createdAt
      eventId

      bracketType
      numSeeds
      poolName
      phaseName
      groupCount

      tournamentName
      eventName
      eventSize
      name
      round
      startAt
      endAt

      loserPlacement
      winnerPlacement
      winnerId
      playerOneId
      playerOneScore
      playerTwoId
      playerTwoScore
    }
  }
`

export const HEAD_TO_HEAD_SUMMARY = gql`
  query HeadToHeadSummary($player1: [ID!], $player2: [ID!], $limit: Int, $videogameSlug: String) {
    headToHeadSummary(player1: $player1, player2: $player2, limit: $limit, videogameSlug: $videogameSlug) {
      player1Wins
      player2Wins
      totalSets
    }
  }
`

export const STITCH_PLAYERS = gql`
  mutation StitchPlayers($players: [CompositeIdInput!]!) {
    stitchPlayers(compositeIds: $players) {
      id
      slug
      handle
      name
      team
    }
  }
`

export const UNSTITCH_PLAYER = gql`
  mutation UnstitchPlayer($compositeId: CompositeIdInput!) {
    unstitchPlayer(compositeId: $compositeId) {
      id
      slug
      handle
      name
      team
    }
  }
`
