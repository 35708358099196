import { Placing } from '../types'
import find from 'lodash/find'
import { isGlobalRegion } from './regions'

export const compactName = (name?: string | null) => {
  if (!name) {
    return ''
  }
  let result = name
  result = result.replace(/Online Cup/i, 'Online')
  result = result.replace(/[^\w\S]Fighting Game[^\w\S]/i, ' ')
  result = result.replace(/Mortal Kombat/i, 'MK')
  result = result.replace(/Pro.Kompetition/i, 'Kompetition')
  return result
}

export const getPlacingName = (item: Placing) => {
  const name = (item.tournamentName || item.eventName || '').replace(/\s+\d{4}\b/, '')
  if (item.seriesPrefix && !name.toLowerCase().includes(item.seriesPrefix.toLowerCase())) {
    return `${item.seriesPrefix} ${name}`
  }
  return name
}

export const getPlacingPoints = (item: Placing) => {
  const found = find(item.pointsEarned, isGlobalRegion)
  if (found && found.points) {
    return found.points
  }
  return 0
}
