import React from 'react'
import { StyleSheet, View } from 'react-native'

import LoginForm from './LoginForm'

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    flexDirection: 'row',
    justifyContent: 'center',
  },
})

export default function LoginPage() {
  return (
    <View style={styles.container}>
      <LoginForm />
    </View>
  )
}
