import * as React from 'react'
import { View, StyleSheet } from 'react-native'
import { Snackbar } from 'react-native-paper'
import { getStored, setStored } from '../state/clientStorage'

const HIDE_PROMPT_TO_WIDEN_SCREEN = 'promptWidenScreen'

const styles = StyleSheet.create({
  container: {
    flex: 0,
  },
})

const AlertMessage: React.FC = () => {
  const shouldHide = getStored(HIDE_PROMPT_TO_WIDEN_SCREEN) === 'true'
  const [visible, setVisible] = React.useState(!shouldHide)

  // NOTE: can color it
  return (
    <View style={styles.container}>
      <Snackbar
        visible={visible}
        onDismiss={() => {
          setVisible(false)
        }}
        action={{
          label: 'OK',
          onPress: () => {
            setStored(HIDE_PROMPT_TO_WIDEN_SCREEN, 'true')
          },
        }}
      >
        To show a 2nd player, widen your screen or try landscape mode on mobile
      </Snackbar>
    </View>
  )
}

export default AlertMessage
