import React from 'react'
import { StyleSheet } from 'react-native'
import { Text } from 'react-native-paper'

import { ERROR_COLOR } from '../styles/colors'

const styles = StyleSheet.create({
  error: {
    color: ERROR_COLOR,
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 5,
    maxHeight: 35,
    overflow: 'hidden',
  },
})

const ErrorText: React.FC = ({ children }) => {
  if (!children) {
    return null
  }
  return <Text style={styles.error}>Our server got hit by a wake-up dp! {children}</Text>
}

export default ErrorText
