import { parseUrlQuery } from '@binary-owls/sdk-node'
import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { Avatar, List, Surface, Text } from 'react-native-paper'
import { Redirect, RouteComponentProps } from 'react-router'
import { VIDEOGAME_NAMES, VIDEOGAME_SLUGS, HIDDEN_VIDEOGAMES, VISIBLE_VIDEOGAMES } from 'encyclopedia'
import { Videogame } from '../Videogames/Videogame'
import { Link, Route, Switch } from '../Router'
import { PlayerVersusPlayer } from './PlayerVersusPlayer'
import NotFound from '../Navigation/404'
import { DuplicatePlayer } from './DuplicatePlayer'
import { FAQ } from '../About/FAQ'
import { useMediaQueries } from '../utils/useScreen'
import { Heading } from '../Heading'
import { images } from '../Videogames/images'
import { PRIMARY_COLOR } from '../styles/colors'
import useCurrentUser from '../state/useCurrentUser'
import { New } from '../AlertMessage/New'
import { isNewlyAddedVideogame } from '../newRelease'

const styles = StyleSheet.create({
  avatar: {
    backgroundColor: 'transparent',
  },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
  },
  videogames: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  videogameCard: { margin: 15 },
  listItem: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: PRIMARY_COLOR,
    marginVertical: 5,
  },
  listSection: {
    width: '100%',
  },
})

const versusRoute = (videogame: string) => `/vs/${videogame}`

const VideogameList: React.FC<{ videogames: string[] }> = ({ videogames }) => {
  return (
    <List.Section style={styles.listSection}>
      {videogames.map(videogame => (
        <Link to={versusRoute(videogame)}>
          <Surface style={styles.listItem}>
            <List.Item
              title={
                <Text>
                  {VIDEOGAME_NAMES[videogame]}
                  {isNewlyAddedVideogame(videogame) && <New small />}
                </Text>
              }
              left={() => <Avatar.Image size={40} source={images[videogame]} style={styles.avatar} />}
            />
          </Surface>
        </Link>
      ))}
    </List.Section>
  )
}

const StatsHome: React.FC<RouteComponentProps> = ({ location }) => {
  const { isTinyScreen, isSmallScreen } = useMediaQueries()
  const { isDev } = useCurrentUser()

  const { p1, p2 } = parseUrlQuery(location.search)
  if (p1 || p2) {
    return <Redirect to={`/${VIDEOGAME_SLUGS.sf5}?p1=${p1 || ''}&p2=${p2 || ''}`} />
  }

  // TODO only allow admins to see private games
  const visibleVideogames = [...(isDev ? HIDDEN_VIDEOGAMES : []), ...VISIBLE_VIDEOGAMES]

  return (
    <View style={styles.container}>
      <Heading subheading="Search head-to-head matchups">Player Search</Heading>
      {isTinyScreen ? (
        <VideogameList videogames={visibleVideogames} />
      ) : (
        <View style={styles.videogames}>
          {visibleVideogames.map(videogame => {
            return (
              <View style={styles.videogameCard}>
                <Link to={versusRoute(videogame)}>
                  <Videogame small={isSmallScreen} videogame={videogame} key={videogame}></Videogame>
                </Link>
              </View>
            )
          })}
        </View>
      )}
      <FAQ />
    </View>
  )
}

export default function Stats() {
  return (
    <Switch>
      <Route exact path="/duplicates/:id" component={DuplicatePlayer} />
      <Route exact path="/duplicates" component={DuplicatePlayer} />
      <Route exact path="/" component={StatsHome} />
      <Route exact path="/vs/:videogameAcronym" component={PlayerVersusPlayer} />
      <Route component={NotFound} />
    </Switch>
  )
}
