import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import uniqWith from 'lodash/uniqWith'
import { isDefaultPortrait, matchPortraitFile } from 'encyclopedia'
import { getImageUrl } from '../utils/image'
import { Placing, Player, RankedPlayer, PlayerSearchItem } from '../types'
import { defaultsFavoringLast } from '@binary-owls/sdk-node'
import { getPlacingName } from './stats'
import { getPlacingYear } from '../utils/date'

export const namePlayer = (player?: Player | null) => player?.handle ?? ''

export const stringifyPlayerHandle = (handle: string) => handle // TODO check profanity?

export const getPlayerId = (player?: RankedPlayer) => {
  return player?.sl ?? ''
}

export const getPortraitSource = (portrait: string | null) => {
  let uri
  if (portrait) {
    if (!isDefaultPortrait(portrait)) {
      const match = matchPortraitFile(portrait)
      if (match) {
        uri = getImageUrl(`static/images/players/${match.pathSlug}/${match.fileName}`)
      }
      if (!uri) {
        uri = portrait
      }
    }
  }
  return { uri: uri || getImageUrl('static/images/unknown.svg') }
}

export const qualify = (value: any) => value && !isDefaultPortrait(value) // default portrait URLs have lowest priority

export const normalizePlayers = (players: RankedPlayer[]): PlayerSearchItem[] => {
  // RankedPlayer is a condensed player with multiple handles, names
  // We want to expand these because fuzzysort can't search a field that's a string array
  const results: PlayerSearchItem[] = []
  players.forEach(player => {
    const length = Math.max(player.hd?.length || 0, player.nm?.length || 0)
    for (let i = 0; i < length; i++) {
      results.push({
        handle: player.hd[i],
        name: player.nm?.[i],
        player,
      })
    }
  })

  return results
}

const arePlacingsEqual = (one: Placing, two: Placing) => {
  if (one.eventSameId && one.eventSameId === two.eventSameId) {
    return true
  }
  if (
    one.placing === two.placing &&
    getPlacingName(one) === getPlacingName(two) &&
    getPlacingYear(one) === getPlacingYear(two)
  ) {
    // when new tournaments have not yet been manually matched, filter them from display
    return true
  }
  return !!one.id && one.id === two.id
}

// TODO sort stuff API-side
export const getPrimaryPlayer = (duplicates: Player[]) => {
  if (!Array.isArray(duplicates) || duplicates.length === 0) {
    return undefined
  }
  if (duplicates.length === 1) {
    return duplicates[0]
  }

  const primaryPlayer = sortBy(duplicates, ({ standings }) => standings.length).reduce((previous, current) => {
    const standings = isEmpty(current.standings) ? previous.standings : [...previous.standings, ...current.standings]
    const placings = uniqWith([...current.placings, ...previous.placings], arePlacingsEqual)

    // For portraits, we favor the one tied to Tours
    let addendum
    if (current.portrait && previous.portrait) {
      if (current.cptId || current.twtId) {
        addendum = {
          portrait: current.portrait,
        }
      }
    }

    return {
      ...defaultsFavoringLast([previous, current], qualify), // MUST BE FIRST IN OBJECT DESTRUCTURING
      ...addendum,
      handle: previous.handle.length < current.handle.length ? previous.handle : current.handle,
      standings,
      placings,
    }
  })

  // If the handle is non-English, then try to append an English handle
  if (!/[a-zA-Z]/.test(primaryPlayer.handle)) {
    const withEnglishHandles = duplicates.filter(d => /[a-zA-Z]/.test(d.handle))
    if (withEnglishHandles?.length) {
      const sorted = sortBy(withEnglishHandles, p => p.handle.length)
      primaryPlayer.handle += ` (${sorted[0].handle})`
    }
  }
  return primaryPlayer
}
