import * as React from 'react'
import { StyleSheet } from 'react-native'
import { Card, Text } from 'react-native-paper'
import { Heading } from '../Heading'
// import { Screenshot } from '../Screenshot'
// import { Link } from '../Router'
// import { TWITTER_PRIMARY_COLOR } from '../styles/colors'
import { ContactUsLink } from './ContactUsLink'
// import { TextLink } from '../Link/TextLink'

const styles = StyleSheet.create({
  faqCard: {
    width: '90%',
    maxWidth: 700,
    marginTop: 10,
    marginBottom: 10,
  },
  faqTitle: {
    textAlign: 'center',
    marginBottom: 30,
  },
  faqQuestion: {
    fontWeight: 'bold',
    marginBottom: 10,
  },
  faqAnswer: {
    textAlign: 'justify',
    marginBottom: 20,
  },
  bold: {
    fontWeight: 'bold',
  },
})

export const FAQ: React.FC = () => {
  return (
    <Card elevation={0} style={styles.faqCard}>
      <Card.Content>
        {/*<Heading subheading="Online Resources for Fighting Games">FGCombo</Heading>*/}
        <Text style={styles.faqTitle}>
          <Heading>FAQ</Heading>
        </Text>
        <Text style={styles.faqQuestion}>What is FGCombo?</Text>
        <Text style={styles.faqAnswer}>
          FGCombo is a humble project to create high-quality resources for the FGC.{' '}
          The <Text style={{ fontWeight: 'bold' }}>Player Search</Text> tracks not only pro players, but also those of us who drown 0-2 in pools!
        </Text>
        {/*<Screenshot uri="/fgcombo-og-image-2021.png" />*/}
        <Text style={styles.faqQuestion}>Someone is showing up twice?</Text>
        <Text style={styles.faqAnswer}>
          We can fix that. <ContactUsLink>Send us</ContactUsLink> the player's name
        </Text>
        <Text style={styles.faqQuestion}>I can't find myself?</Text>
        <Text style={styles.faqAnswer}>
          <ContactUsLink>Send us</ContactUsLink> your gamer tag and any tournament you entered
        </Text>
        <Text style={styles.faqQuestion}>Can I update my name and picture?</Text>
        <Text style={styles.faqAnswer}>
          In the future, we will add more things you can do. This will take some time because we have a single volunteer developing it in a dank basement.  Check Twitter for updates:
        </Text>
        {/*<Text style={styles.faqQuestion}>How do I get my tournaments onto FGCombo?</Text>*/}
        {/*<Text style={styles.faqAnswer}>*/}
        {/*  Please tell us about the tournament: name, date, and where the brackets are hosted.*/}
        {/*</Text>*/}
        {/*<Text style={styles.faqQuestion}>Can I suggest adding another game?</Text>*/}
        {/*<Text style={styles.faqAnswer}>*/}
        {/*  Let us know what you're interested in! We are already looking at anime and classic FGs.*/}
        {/*</Text>*/}
        {/*<Text style={styles.faqQuestion}>I went 0-2 at a tournament but my result isn't showing?</Text>*/}
        {/*<Text style={styles.faqAnswer}>*/}
        {/*  Why do you want to be shown drowning in pools? Get back in Training Mode work on your anti-airs, and we'll see*/}
        {/*  you in EVO top 8 one day!*/}
        {/*</Text>*/}
        {/*<Text style={styles.faqQuestion}>When is the mobile app coming?</Text>*/}
        {/*<Text style={styles.faqAnswer}>*/}
        {/*  Android/iOS apps are still in Training Mode trying to throw fireballs. The site is mobile-friendly, though, so*/}
        {/*  just visit fgcombo.com*/}
        {/*</Text>*/}
        {/*<Text style={styles.faqQuestion}>What happened to the Combo Builder?</Text>*/}
        {/*<Text style={styles.faqAnswer}>*/}
        {/*  Because we're working on many features, Combo Builder is on hiatus for a hot minute. But we promise it'll be*/}
        {/*  back and it'll support multiple games. For now, you can still access the older Combo Builder at{' '}*/}
        {/*  <TextLink url="https://legacy.fgcombo.com">legacy.fgcombo.com</TextLink>*/}
        {/*</Text>*/}
        {/*<Text style={styles.faqQuestion}>How are players ranked?</Text>*/}
        {/*<Text style={styles.faqAnswer}>*/}
        {/*  We use a simple ranking system recommended by SRK Ranking, where we account for players' activity in the last*/}
        {/*  1-2 years, and randomize them periodically so that great players who don't compete regularly can still show*/}
        {/*  up.*/}
        {/*</Text>*/}

        {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
        { <div dangerouslySetInnerHTML={{ __html: rawHtml }} /> }
      </Card.Content>
    </Card>
  )
}

const rawHtml = `
    <div style="text-align: center">
      <span style="width: 100px; height: 100px">
        <a href="https://twitter.com/FGCombo" target="_blank">
          <img width="40px" src="/icon-twitter.svg" />
        </a>
      </span>
      <span style="width: 100px; height: 100px">
        <a href="https://discord.gg/tgHhPg2" target="_blank">
          <img width="40px" src="/icon-discord.svg" />
        </a>
      </span>
      <span style="width: 100px; height: 100px">
        <a href="https://instagram.com/FGCombo" target="_blank">
          <img width="40px" src="/icon-instagram.svg" />
        </a>
      </span>
    </div>
`