import url from 'url'
import { ParsedUrlQuery } from 'querystring'

import { API_URL } from '../config'

export const getApi = (pathname: string, query = {}) => {
  const appendix = url.format({
    pathname,
    query,
  })
  return `${API_URL}${appendix}`
}

export const getRequest = (pathname: string, query: ParsedUrlQuery = {}, options?: RequestInit) => {
  return fetch(getApi(pathname, query), options).then(res => {
    if (res.ok) {
      return res.json()
    }
    return res.json().then(body => {
      throw Error(`${res.status}: ${body.message || res.statusText}`)
    })
  })
}

export const postRequest = (pathname: string, body: any, query?: ParsedUrlQuery) => {
  return fetch(getApi(pathname, query), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }).then(res => res.json())
}
