import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { Heading } from '../Heading'
import { Text } from 'react-native-paper'
import { PRIMARY_COLOR } from '../styles/colors'
import { DiscordInviteLink, TwitterDirectMessageLink } from '../Social/SocialMediaLinks'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  text: {
    marginTop: 20,
    fontSize: 15,
    fontWeight: '500',
  },
  emailText: {
    color: PRIMARY_COLOR,
  },
})

export const ContactUs: React.FC = () => {
  return (
    <View style={styles.container}>
      <Heading>Contact</Heading>
      <Text style={styles.text}>Please tell us if you see an issue with a player, tournament, or data:</Text>
      <Text style={styles.text}>
        <TwitterDirectMessageLink>Twitter</TwitterDirectMessageLink>
      </Text>
      <Text style={styles.text}>
        <DiscordInviteLink>Discord</DiscordInviteLink>
      </Text>
    </View>
  )
}
