import { getPlayerName } from 'encyclopedia'
import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { Avatar, Card, Divider, Text } from 'react-native-paper'
import head from 'lodash/head'
import sortBy from 'lodash/sortBy'
import pluralize from 'pluralize'
import { Standing } from '../types'
import { sharedStyles } from '../styles'
import { getPortraitSource, stringifyPlayerHandle } from '../common/players'
import { isGlobalRegion, SF_REGIONS } from '../common/regions'
import { getStandingYear } from '../utils/date'
import { GetPlayer_playerWithStats_standings } from './__generated__/GetPlayer'
import { useQuery } from '@apollo/react-hooks'
import * as GetCharactersPlayed from './__generated__/GetCharactersPlayed'
import { GET_CHARACTERS_PLAYED } from './gql'

const styles = StyleSheet.create({
  card: {
    paddingHorizontal: 4,
    marginBottom: 20,
  },
  title: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: 8,
  },
  team: {
    textAlign: 'center',
    textAlignVertical: 'center',
    fontSize: 18,
  },
  name: {
    color: '#000',
    textAlign: 'center',
    textAlignVertical: 'center',
    fontWeight: 'bold',
    fontSize: 35,
    marginTop: 5,
    marginBottom: 10,
  },
  detail: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  textLabel: {
    flex: 3,
    fontSize: 14,
    marginVertical: 4,
    paddingRight: 4,
    textAlign: 'right',
  },
  textFieldValue: {
    flex: 5,
    fontSize: 16,
    fontWeight: 'bold',
    marginVertical: 4,
    paddingLeft: 4,
    textAlign: 'left',
  },
  button: {
    margin: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  portrait: {
    alignItems: 'center',
  },
  divider: {
    backgroundColor: '#ddd',
    width: '60%',
    minWidth: 250,
    marginVertical: 16,
  },
})

const renderDetail = (label: string, value: any, defaultValue?: any) => {
  const finalValue = value || defaultValue
  if (!finalValue) {
    return null
  }
  return (
    <View style={styles.detail}>
      <Text numberOfLines={1} style={styles.textLabel}>
        {label}
      </Text>
      <Text style={styles.textFieldValue} numberOfLines={3}>
        {finalValue}
      </Text>
    </View>
  )
}

const renderStanding = (standing: Standing) => {
  const { qualified, region, points, rank } = standing
  const year = getStandingYear(standing)
  const yearString = year === new Date().getFullYear() ? '' : `${year}`
  return (
    <View>
      {qualified
        ? renderDetail(`${yearString} Qualified`, 'Yes')
        : [
            renderDetail(
              `${yearString} Rank ${region && region !== SF_REGIONS.global ? `(${region})` : ''}`,
              `${rank}`,
            ),
            renderDetail(`${yearString} Points`, points),
          ]}
    </View>
  )
}

interface Props {
  slug: string
  handle: string
  name: string | null
  team: string | null
  portrait: string | null
  country: string | null
  standings: GetPlayer_playerWithStats_standings[]
  videogameSlug?: string
}

const CHARACTERS_SHOWN_LIMIT = 8

export const PlayerCard: React.FC<Props> = ({ slug, name, handle, portrait, country, standings, videogameSlug }) => {
  const { data: charactersPlayed } = useQuery<GetCharactersPlayed.GetCharactersPlayed>(GET_CHARACTERS_PLAYED, {
    variables: {
      identifier: slug,
      videogameSlug,
    },
  })

  // TODO show correct teams
  const standing = head(
    sortBy(standings, ({ region, leagueEndAt }: Standing) => {
      // non-regional standings are more importantly sorted
      return (isGlobalRegion({ region }) ? 0 : 1) - new Date(leagueEndAt).getTime()
    }),
  )
  // const charList = characters || []
  // TODO show correct characters
  const charList = [] as any[]

  let characterString
  if (charactersPlayed?.charactersPlayed.length) {
    const sum = charactersPlayed.charactersPlayed.map(chara => chara.playCount).reduce((prev, curr) => prev + curr, 0)
    characterString = charactersPlayed.charactersPlayed
      .slice(0, CHARACTERS_SHOWN_LIMIT)
      .filter(ch => {
        const percentage = (ch.playCount / sum) * 100
        return percentage > 1.0
      })
      .map(ch => {
        const percentage = Math.trunc((ch.playCount / sum) * 100)
        return percentage === 100 ? ch.name : `${ch.name} ${percentage}%`
      })
      .join(', ')
  }

  return (
    <Card.Content style={styles.card}>
      <View style={styles.portrait}>
        {/*<Text style={styles.team}>{team}</Text>*/}
        <Text style={styles.name}>{stringifyPlayerHandle(handle)}</Text>
        <Avatar.Image size={128} style={sharedStyles.avatar} source={getPortraitSource(portrait)} />
        <Divider style={styles.divider} />
      </View>
      <View>
        {standing ? renderStanding(standing) : null}
        {renderDetail(pluralize('Character', charList.length), charList.join(', '))}
        {/*{renderDetail('Name', getPlayerName(name))}*/}
        {renderDetail('From', country)}
        {renderDetail('Characters', characterString)}
      </View>
    </Card.Content>
  )
}
