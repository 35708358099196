import * as React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { StyleSheet, View } from 'react-native'
import { Button } from 'react-native-paper'

import { GET_LOGGED_IN_USER } from '../Users/gql'
import { GetLoggedInUser } from '../Users/__generated__/GetLoggedInUser'
import useCurrentUser from '../state/useCurrentUser'
import { APP_NAME } from '../config'
import { isDevelopmentMode } from '../dev/devMode'
import { useMediaQueries } from '../utils/useScreen'

interface NavigationProps {
  atHome: boolean
  atUserProfile: boolean
  goBack: () => any
  goHome: () => any
  goLogin: () => any
  goToProfile: () => any
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: 10,
  },
  left: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: '33%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  middle: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: '33%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 5,
  },
  right: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: '33%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  home: {
    fontWeight: 'bold',
  },
  loadingSplash: {
    height: '110%',
  },
  profileButton: {
    marginRight: 10,
  },
})

export const NavigationBar: React.FC<NavigationProps> = ({ atHome, atUserProfile, goHome, goLogin, goToProfile }) => {
  const { isSmallScreen } = useMediaQueries()
  const { clearUser, setUser } = useCurrentUser()
  const { data, loading } = useQuery<GetLoggedInUser>(GET_LOGGED_IN_USER, {
    onCompleted(getCurrentUser) {
      if (getCurrentUser?.me) {
        setUser({
          email: getCurrentUser.me.email,
          username: getCurrentUser.me?.username,
          twitterImage: getCurrentUser.me?.twitterImage,
          twitterName: getCurrentUser.me?.twitterName,
          twitterScreenName: getCurrentUser.me?.twitterScreenName,
          roles: getCurrentUser.me?.roles,
        })
      } else {
        clearUser()
      }
    },
    onError() {
      clearUser()
    },
    errorPolicy: 'ignore',
  })

  let rightComponents
  if (data?.me) {
    rightComponents = (
      <Button
        mode={isSmallScreen || atUserProfile ? 'text' : 'outlined'}
        labelStyle={isSmallScreen ? { marginHorizontal: 0 } : undefined}
        style={styles.profileButton}
        disabled={loading}
        icon="account-circle-outline"
        onPress={atUserProfile ? undefined : goToProfile}
      >
        &nbsp;
        {!isSmallScreen && ((data.me?.twitterScreenName || data.me?.twitterName || data.me?.username) ?? 'Profile')}
      </Button>
    )

    // TODO remove DevMode when user feature is ready
  } else if (isDevelopmentMode()) {
    rightComponents = (
      <Button
        mode={isSmallScreen ? 'text' : 'outlined'}
        labelStyle={isSmallScreen ? { marginHorizontal: 0 } : undefined}
        disabled={loading}
        icon="account-circle-outline"
        onPress={goLogin}
      >
        &nbsp;{!isSmallScreen && 'Log In'}
      </Button>
    )
  }

  return (
    <View style={styles.container}>
      <View style={styles.left}>
        <Button
          icon={atHome ? undefined : 'home'}
          mode={atHome ? 'text' : 'outlined'}
          uppercase={false}
          onPress={atHome ? undefined : () => goHome()}
        >
          {APP_NAME}
        </Button>
      </View>
      <View style={styles.middle}></View>
      <View style={styles.right}>{rightComponents}</View>
    </View>
  )
}
