type BracketType = 'single' | 'double'

// TODO deprecate
export const isValidTopN = (topN: number) => {
  if (topN < 4) {
    return false
  }
  const exponent = Math.log2(topN)
  const lower = 2 ** Math.floor(exponent)
  const upper = 2 ** Math.ceil(exponent)
  return [lower, upper, (lower + upper) / 2].includes(topN)
}

/**
 * Get placing based on an exponent that represents the round
 * @param exponent is akin to the round in which the placing is in
 * @param bracketType default 'double'
 */
export const getPlacingFromExponent = (exponent: number, bracketType: BracketType = 'double') => {
  if (bracketType === 'single' && exponent > 1 && exponent % 1 !== 0.5) {
    throw Error(`exponent ${exponent} is invalid for a single-elim bracket`)
  }

  if (exponent === 0.5) {
    return 1
  }
  if (exponent === 1) {
    return 2
  }
  if (exponent % 1 === 0) {
    return 2 ** exponent * 0.75 + 1
  }
  return 2 ** Math.floor(exponent) + 1
}

/**
 * Get the Top N and exponent for a given placing
 * @param placing
 */
export const getPlacingMetadata = (placing: number) => {
  let e = 1

  while (e < 30) {
    const topN = Math.pow(2, e)

    const winnerPlacing = e === 1 ? 1 : topN / 2 + 1
    const winnerTopN = Math.round(topN * 0.75)
    if (placing === winnerPlacing) {
      return {
        topN: winnerTopN,
        exponent: e - 0.5,
      }
    }

    const loserPlacing = e === 1 ? 2 : winnerTopN + 1
    if (placing === loserPlacing) {
      return {
        topN,
        exponent: e,
      }
    }

    if (placing <= topN) {
      throw Error(`${placing} as a placing number is invalid`)
    }

    e++
  }

  throw Error(`Invalid placing ${placing}`)
}

function isValidEliminationBracketPlacing(placing: number) {
  try {
    getPlacingMetadata(placing)
    return true
  } catch {
    return false
  }
}

/**
 * Given top N, retrieve the exponent representing it
 * @param topN
 */
export const getExponentFromTopN = (topN: number) => getPlacingMetadata(topN + 1).exponent - 0.5

/**
 * Get the placing of player who drowned in pools.
 *
 * For pool placing starting from 1st, the actual placing will be different depending on the top N final phase
 * @param poolPlacing
 * @param numberOfGroups
 * @param offsetTopN
 * @param advancements
 */
// NOTE: this function is not affected by single/double bracket type
export const getActualPlacing = (
  poolPlacing: number,
  numberOfGroups: number,
  offsetTopN: number,
  advancements: number,
) => {
  if (advancements > 8) {
    // e.g. 8 advancing, 1 pool only https://challonge.com/UNGAGGSTM2/standings
    throw Error(`${advancements} players advancing is not realistic`)
  } else if (advancements <= 0) {
    throw Error(`${advancements} advancements is invalid`)
  }

  if (numberOfGroups < 1) {
    throw Error(`${numberOfGroups} groups is not valid`)
  }

  if (poolPlacing <= advancements) {
    throw Error(`Placing ${poolPlacing} is already in the ${advancements} that advanced to top ${offsetTopN}`)
  }

  const placingsAbovePlacing = poolPlacing - 1
  return offsetTopN + numberOfGroups * (placingsAbovePlacing - advancements) + 1

  // Older way, doesn't account for Top N where N is not typical e.g. 10

  // const meta = getPlacingMetadata(poolPlacing)
  // const deltaExponent = Math.log2(offsetTopN / advancements)
  // return getPlacingFromExponent(meta.exponent + deltaExponent)
}
