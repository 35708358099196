import { Placing } from '../types'
import find from 'lodash/find'
import get from 'lodash/get'
import { SF_REGIONS } from './regions'

export const getPoints = ({ pointsEarned }: Placing, specificRegion = SF_REGIONS.global): number => {
  const found = find(pointsEarned, ({ region }) => region === specificRegion)
  return get(found, 'points') || 0
}

export const SF_EVENT_TYPES = {
  evo: 'Evolution',
  premier: 'Premier',
  ranking: 'Ranking',
  capcomCup: '',
}

export const isBigEvent = (eventType?: string | null) => {
  return eventType && [SF_EVENT_TYPES.premier].some(item => new RegExp(item, 'i').test(eventType))
}

export const isBiggestEvent = (eventType: string | null) => {
  return (
    eventType &&
    (SF_EVENT_TYPES.capcomCup === eventType || [SF_EVENT_TYPES.evo].some(item => new RegExp(item, 'i').test(eventType)))
  )
}

// TODO deprecate
const SF_EVENT_IMPORTANCES = {
  [SF_EVENT_TYPES.evo]: 1750,
  [SF_EVENT_TYPES.premier]: 700,
  [SF_EVENT_TYPES.ranking]: 200,
  [SF_EVENT_TYPES.capcomCup]: 1500, // TODO should CPT be always shown?
}

const OFFSET = 32
const COEFFICIENT = 3.7

// TODO deprecate
export const calculateImportance = (item: Placing) => {
  const { eventType, placing } = item

  let score = 0

  const points = getPoints(item)
  if (points) {
    score = points
  } else if (eventType) {
    const importance = SF_EVENT_IMPORTANCES[eventType]
    const number = (Math.exp(((OFFSET - placing) / OFFSET) * COEFFICIENT) / Math.exp(COEFFICIENT)) * importance
    score = number || 0
  }

  // TODO older results also impact the score

  return score
}
