import gql from 'graphql-tag'

// User from server
export const GET_LOGGED_IN_USER = gql`
  query GetLoggedInUser {
    me {
      email
      username
      twitterImage
      twitterName
      twitterScreenName
      roles
    }
  }
`

// Cached user
export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser @client {
      email
      username
      twitterImage
      twitterName
      twitterScreenName
      roles
    }
  }
`

export const LOG_IN_USER = gql`
  mutation LogIn($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      email
      username
      twitterImage
      twitterName
      twitterScreenName
      roles
    }
  }
`

export const LOG_OUT_USER = gql`
  mutation LogOut {
    logout
  }
`
