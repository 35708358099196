import { ApolloCache } from 'apollo-cache'
import { Resolvers } from 'apollo-client'

const DISMISSED_POPUPS_KEY = 'dismissedPopups'

type ResolverFn = (parent: any, args: any, { cache }: { cache: ApolloCache<any> }) => any

interface ResolverMap {
  [field: string]: ResolverFn
}

interface AppResolvers extends Resolvers {
  Query: ResolverMap
}

// Local state resolvers
export const resolvers: AppResolvers = {
  Query: {},
  // Mutation: {
  //   addOrRemovePopup: (_, { message, pressed }: { message: string, pressed?: boolean }, { cache }): string[] => {
  //     const result = cache.readQuery<AddPopup.AddPopup>({ query: TOGGLE_POPUP })
  //     if (result) {
  //       const { currentPopups } = result
  //
  //       if (!message) {
  //         return currentPopups
  //       }
  //
  //       let currentlyDismissed
  //       try {
  //         currentlyDismissed = JSON.parse(getItem(DISMISSED_POPUPS_KEY) ?? '[]')
  //       } catch {
  //         currentlyDismissed = []
  //       }
  //
  //       if (currentlyDismissed.includes(message)) {
  //         return currentPopups
  //       }
  //
  //       const data = currentPopups.concat(message)
  //       cache.writeQuery<AddPopup.AddPopup, AddPopup.AddPopupVariables>({
  //         query: TOGGLE_POPUP,
  //         data: {
  //           currentPopups: data,
  //         },
  //       })
  //
  //       // Any dismissed popups is saved in memory
  //       if (pressed) {
  //         setItem(DISMISSED_POPUPS_KEY, JSON.stringify(currentlyDismissed.concat(message)))
  //       }
  //       return data
  //     }
  //     return []
  //   },
  // },
}
