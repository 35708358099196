import { useQuery } from '@apollo/react-hooks'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { StyleSheet, View } from 'react-native'
import { Button, Card } from 'react-native-paper'

import { GET_PLAYER, GET_PLAYERS, HEAD_TO_HEAD_SETS, HEAD_TO_HEAD_SUMMARY } from './gql'
import { GetPlayer, GetPlayerVariables } from './__generated__/GetPlayer'
import { GetPlayers, GetPlayersVariables } from './__generated__/GetPlayers'
import { HeadToHeadSets, HeadToHeadSetsVariables } from './__generated__/HeadToHeadSets'
import * as HeadToHeadSummaryTypes from './__generated__/HeadToHeadSummary'
import { Player, PlayerSearchItem } from '../types'
import { getPrimaryPlayer, namePlayer, normalizePlayers } from '../common/players'
import { PlayerSearch, SearchPlayer } from '../Search/PlayerSearch'
import { PlayerCard } from './PlayerCard'
import Placings from './Placings'
import HeadToHead from './HeadToHead'
import VersusSymbol from './Vs'
import AlertMessage from '../AlertMessage/AlertMessage'
import { APP_NAME, CDN_URL } from '../config'
import { useNetwork } from '../state/useNetwork'
import ErrorDisplay from '../Notifications/ErrorDisplay'
import GlobalError from '../Notifications/GlobalError'
import { PLAYER_PROFILE_WIDTH } from '../styles'
import { PlayerProfile } from './PlayerProfile'
import HeadToHeadSummary from './HeadToHeadSummary'
import { LoadingEllipses } from '../LoadingEllipses'

const { useEffect, useState } = React

const styles = StyleSheet.create({
  alignRight: {
    justifyContent: 'flex-end',
  },
  searchButton: {
    marginVertical: 16,
  },
  center: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerItem: {
    flex: 1,
    flexDirection: 'column',
    flexWrap: 'nowrap',
    marginHorizontal: 16,
  },
  container: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  player: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: PLAYER_PROFILE_WIDTH,
    minWidth: PLAYER_PROFILE_WIDTH,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
})

const usePlayersAPI = (videogameSlug: string) => {
  const [players, setPlayers] = useState([] as PlayerSearchItem[])
  const { handleNetworkError, handleNetworkSuccess } = useNetwork()

  const { error, loading } = useQuery<GetPlayers, GetPlayersVariables>(GET_PLAYERS, {
    variables: { videogameSlug },
    errorPolicy: 'none',
    fetchPolicy: 'no-cache', // no-cache since this query only runs for admins
    onError(err) {
      handleNetworkError(err)
    },
    onCompleted(data) {
      handleNetworkSuccess()
      setPlayers(normalizePlayers(data.playersRanked))
    },
  })

  return { error, loading, players }
}

// pulls from AWS CloudFront
const usePlayersCDN = (videogameSlug: string) => {
  const { handleNetworkError, handleNetworkSuccess } = useNetwork()
  const [players, setPlayers] = useState([] as PlayerSearchItem[])
  const [error, setError] = useState<any>()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (videogameSlug) {
      setLoading(true)
      fetch(`${CDN_URL}/players/${videogameSlug}`)
        .then(response => response.json())
        .then((data: any) => {
          handleNetworkSuccess()
          setPlayers(normalizePlayers(data))
        })
        .catch(err => {
          handleNetworkError(err)
          setError(err)
        })
        .then(() => {
          setLoading(false)
        })
    } else {
      setPlayers([])
    }
    return () => {
      setPlayers([])
      setError(undefined)
      setLoading(false)
    }
  }, [videogameSlug])

  return { error, loading, players }
}

const usePlayer = (id: string, videogameSlug?: string) => {
  const [error, setError] = useState<Error>()
  const { data, loading } = useQuery<GetPlayer, GetPlayerVariables>(GET_PLAYER, {
    skip: !id,
    variables: { id, videogameSlug },
    errorPolicy: 'none',
    onError(err) {
      setError(err)
    },
  })

  const duplicates = data?.playerWithStats

  return {
    error,
    primaryProfile: duplicates && getPrimaryPlayer(duplicates),
    profiles: duplicates,
    loading,
  }
}

const useHeadToHead = (p1?: Player[] | null, p2?: Player[] | null, videogameSlug?: string) => {
  const { data, loading } = useQuery<HeadToHeadSets, HeadToHeadSetsVariables>(HEAD_TO_HEAD_SETS, {
    variables: {
      player1: p1?.map(({ id }) => id),
      player2: p2?.map(({ id }) => id),
      videogameSlug,
    },
  })
  return {
    sets: data?.headToHead,
    loading,
  }
}

const useHeadToHeadSummary = (p1?: Player[] | null, p2?: Player[] | null, videogameSlug?: string) => {
  const { data, loading } = useQuery<
    HeadToHeadSummaryTypes.HeadToHeadSummary,
    HeadToHeadSummaryTypes.HeadToHeadSummaryVariables
  >(HEAD_TO_HEAD_SUMMARY, {
    variables: {
      player1: p1?.map(({ id }) => id),
      player2: p2?.map(({ id }) => id),
      videogameSlug,
    },
  })
  return {
    data: data?.headToHeadSummary,
    loading,
  }
}

interface SearchButtonProps {
  onReset: () => any
  onRight?: boolean
}

const SearchButton: React.FC<SearchButtonProps> = ({ onReset, onRight = false }) => (
  <Card.Actions style={[styles.searchButton, onRight ? styles.alignRight : null]}>
    <Button icon="magnify" mode="contained" onPress={onReset}>
      Search
    </Button>
  </Card.Actions>
)
const renderSearcher = (
  initialSearch: string,
  loadingPlayers: boolean,
  playerSearchItems: PlayerSearchItem[],
  searchPlayer: SearchPlayer,
) => {
  return (
    <PlayerSearch
      initialSearch={initialSearch}
      loading={loadingPlayers}
      searchItems={playerSearchItems}
      setPlayer={searchPlayer}
    />
  )
}

const renderPlayerProfile = ({
  id,
  loading,
  resetPlayer,
  initialSearch,
  loadingPlayers,
  players,
  searchPlayer,
  player,
  onRight = false,
  error,
  videogameSlug,
}: {
  id: string
  loading: boolean
  resetPlayer: () => any
  initialSearch: string
  loadingPlayers: boolean
  players: PlayerSearchItem[]
  searchPlayer: SearchPlayer
  player?: Player | null
  onRight?: boolean
  error: any
  videogameSlug?: string
}) => {
  let content
  if (id) {
    content = (
      <React.Fragment>
        {error && <ErrorDisplay error={error} />}
        <View style={styles.containerItem}>
          <SearchButton onReset={resetPlayer} onRight={onRight} />
        </View>
        <View style={styles.containerItem}>
          <PlayerProfile id={id} loading={loading} data={player && [player]} videogameSlug={videogameSlug} />
        </View>
        {player && (
          <View style={styles.containerItem}>
            <SearchButton onReset={resetPlayer} onRight={onRight} />
          </View>
        )}
      </React.Fragment>
    )
  } else {
    content = renderSearcher(initialSearch, loadingPlayers, players, searchPlayer)
  }

  return <View style={styles.player}>{content}</View>
}

interface SharedProps {
  navigate(one: string, two: string, newState?: { search1?: string; search2?: string }): void

  p1: any
  p2: any
  search1: string
  search2: string
  showSecondPlayer: boolean
}

interface VersusProps extends SharedProps {
  error: any
  loadingPlayers: boolean
  players: PlayerSearchItem[]
  videogameSlug?: string
}

interface Props extends SharedProps {
  videogameSlug: string
}

export const AdminVersus: React.FC<Props> = props => {
  const { videogameSlug } = props
  const { error, loading: loadingPlayers, players } = usePlayersAPI(videogameSlug)

  return (
    <Versus
      key="adminversus"
      {...props}
      error={error}
      loadingPlayers={loadingPlayers}
      players={players}
      videogameSlug={videogameSlug}
    />
  )
}

export const NonadminVersus: React.FC<Props> = props => {
  const { videogameSlug } = props
  const { error, loading: loadingPlayers, players } = usePlayersCDN(videogameSlug)

  return (
    <Versus
      key={`versus-${videogameSlug}`}
      {...props}
      error={error}
      loadingPlayers={loadingPlayers}
      players={players}
      videogameSlug={videogameSlug}
    />
  )
}

const Versus: React.FC<VersusProps> = props => {
  // TODO use search1, search2
  const { navigate, p1, p2, search1, search2, showSecondPlayer, videogameSlug, error, loadingPlayers, players } = props

  const id1 = (Array.isArray(p1) ? p1[0] : p1) || ''
  const id2 = (Array.isArray(p2) ? p2[0] : p2) || ''

  const { error: error1, primaryProfile: player1, profiles: players1, loading: loadingPlayer1 } = usePlayer(
    id1,
    videogameSlug,
  )
  const { error: error2, primaryProfile: player2, profiles: players2, loading: loadingPlayer2 } = usePlayer(
    id2,
    videogameSlug,
  )

  const { sets: h2hSets, loading: loadingH2h } = useHeadToHead(players1, players2, videogameSlug)
  const { data: h2hSummary, loading: loadingH2hSummary } = useHeadToHeadSummary(players1, players2, videogameSlug)
  const resetPlayer1 = () => {
    navigate('', id2)
  }
  const resetPlayer2 = () => {
    navigate(id1, '')
  }

  const searchPlayer1: SearchPlayer = (id, searchString) => navigate(id, id2, { search1: searchString })
  const searchPlayer2: SearchPlayer = (id, searchString) => navigate(id1, id, { search2: searchString })
  let body
  if (!showSecondPlayer) {
    body = (
      <>
        <AlertMessage />
        <View style={styles.container}>
          {renderPlayerProfile({
            id: id1,
            loading: loadingPlayer1,
            resetPlayer: resetPlayer1,
            initialSearch: search1,
            loadingPlayers,
            players,
            searchPlayer: searchPlayer1,
            player: player1,
            error: error ? undefined : error1,
            videogameSlug,
          })}
        </View>
      </>
    )
  } else {
    if (
      player1 &&
      player2 &&
      players1 &&
      players2 &&
      (loadingH2h || h2hSets?.length || loadingH2hSummary || h2hSummary?.totalSets)
    ) {
      // If matches exist between these 2 players
      const searchButtons = (
        <View style={styles.container}>
          <View style={styles.containerItem}>
            <SearchButton onReset={resetPlayer1} />
          </View>
          <View style={styles.containerItem}>
            <SearchButton onReset={resetPlayer2} onRight={true} />
          </View>
        </View>
      )
      body = (
        <>
          {searchButtons}
          <View style={styles.container}>
            <View style={styles.containerItem}>
              <PlayerCard {...player1} videogameSlug={videogameSlug} />
            </View>
            <View style={[styles.containerItem, styles.center]}>
              <HeadToHeadSummary data={h2hSummary} />
              {(loadingH2h || loadingH2hSummary) && <LoadingEllipses fontSize={50} />}
              <HeadToHead sets={h2hSets} leftPlayer={players1} />
            </View>
            <View style={styles.containerItem}>
              <PlayerCard {...player2} videogameSlug={videogameSlug} />
            </View>
          </View>
          <View style={styles.container}>
            <View style={styles.containerItem}>
              <Placings placings={player1.placings} />
            </View>
            <View style={styles.containerItem}>
              <Placings placings={player2.placings} />
            </View>
          </View>
          {searchButtons}
        </>
      )
    } else {
      body = (
        <View style={styles.container}>
          {renderPlayerProfile({
            id: id1,
            loading: loadingPlayer1,
            resetPlayer: resetPlayer1,
            initialSearch: search1,
            loadingPlayers,
            players,
            searchPlayer: searchPlayer1,
            player: player1,
            error: error ? undefined : error1,
            videogameSlug,
          })}
          <VersusSymbol />
          {renderPlayerProfile({
            id: id2,
            loading: loadingPlayer2,
            resetPlayer: resetPlayer2,
            initialSearch: search2,
            loadingPlayers,
            players,
            searchPlayer: searchPlayer2,
            player: player2,
            onRight: true,
            error: error ? undefined : error2,
            videogameSlug,
          })}
        </View>
      )
    }
  }

  let title = APP_NAME
  if (player1 || player2) {
    title = namePlayer(player1) || '?'
    if (player2) {
      title += ` v ${namePlayer(player2)}`
    }
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <GlobalError />
      {body}
    </>
  )
}
