import gql from 'graphql-tag'

export const typeDefs = gql`
  "Indicates that the client should resolve the field value locally as part of a query, e.g. in a React component"
  directive @client on FIELD

  extend type Query {
    currentUser: User
    currentVideogame: String
    currentYear: Int!
    lastNetworkMessage: DisplayMessage
  }

  type DisplayMessage {
    code: Int
    error: String
    message: String
    reason: String
  }
`
