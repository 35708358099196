export const VIDEOGAME_SLUGS = {
  bbcf: 'bbcf',
  bbtag: 'bbtag',
  dbfz: 'dbfz',
  ggs: 'ggs',
  ggxx: 'ggxx',
  ggxrd: 'ggxrd',
  kof14: 'kof14',
  kof15: 'kof15',
  mk9: 'mk9',
  mk10: 'mk10',
  mk11: 'mk11',
  mvc3: 'mvc3',
  samsho: 'samsho',
  sc5: 'sc5',
  sc6: 'sc6',
  sg: 'sg',
  sf4: 'sf4',
  sf5: 'sf5',
  t6: 't6',
  t7: 't7',
  ttt: 'ttt',
  ttt2: 'ttt2',
  gbvs: 'gbvs',
  sfxt: 'sfxt',
  uni: 'uni',
}

export const VIDEOGAME_NAMES = Object.freeze({
  [VIDEOGAME_SLUGS.bbcf]: 'BlazBlue Central Fiction',
  [VIDEOGAME_SLUGS.bbtag]: 'BlazBlue Cross Tag Battle',
  [VIDEOGAME_SLUGS.dbfz]: 'Dragon Ball FighterZ',
  [VIDEOGAME_SLUGS.ggs]: 'Guilty Gear -Strive-',
  [VIDEOGAME_SLUGS.ggxx]: 'Guilty Gear XX',
  [VIDEOGAME_SLUGS.ggxrd]: 'Guilty Gear Xrd',
  [VIDEOGAME_SLUGS.kof14]: 'King of Fighters 14',
  [VIDEOGAME_SLUGS.kof15]: 'King of Fighters 15',
  [VIDEOGAME_SLUGS.mk9]: 'Mortal Kombat 9',
  [VIDEOGAME_SLUGS.mk10]: 'Mortal Kombat X',
  [VIDEOGAME_SLUGS.mk11]: 'Mortal Kombat 11',
  [VIDEOGAME_SLUGS.mvc3]: 'Marvel VS Capcom 3',
  [VIDEOGAME_SLUGS.samsho]: 'Samurai Shodown',
  [VIDEOGAME_SLUGS.sc5]: 'SOULCALIBUR V',
  [VIDEOGAME_SLUGS.sc6]: 'SOULCALIBUR VI',
  [VIDEOGAME_SLUGS.sg]: 'Skullgirls',
  [VIDEOGAME_SLUGS.sf4]: 'Street Fighter 4',
  [VIDEOGAME_SLUGS.sf5]: 'Street Fighter V',
  [VIDEOGAME_SLUGS.t6]: 'Tekken 6',
  [VIDEOGAME_SLUGS.t7]: 'Tekken 7',
  [VIDEOGAME_SLUGS.ttt]: 'Tekken Tag Tournament',
  [VIDEOGAME_SLUGS.ttt2]: 'Tekken Tag Tournament 2',
  [VIDEOGAME_SLUGS.gbvs]: 'Granblue Versus',
  [VIDEOGAME_SLUGS.sfxt]: 'Street Fighter X Tekken',
  [VIDEOGAME_SLUGS.uni]: 'Under Night In-Birth',
})

export const VIDEOGAME_ACRONYMS = Object.freeze({
  [VIDEOGAME_SLUGS.bbcf]: 'BBCF',
  [VIDEOGAME_SLUGS.bbtag]: 'BBTag',
  [VIDEOGAME_SLUGS.dbfz]: 'DBFZ',
  [VIDEOGAME_SLUGS.ggs]: 'GGS',
  [VIDEOGAME_SLUGS.ggxx]: 'GGXX',
  [VIDEOGAME_SLUGS.ggxrd]: 'GGXrd',
  [VIDEOGAME_SLUGS.kof14]: 'KOF14',
  [VIDEOGAME_SLUGS.kof15]: 'KOF15',
  [VIDEOGAME_SLUGS.mk9]: 'MK9',
  [VIDEOGAME_SLUGS.mk10]: 'MKX',
  [VIDEOGAME_SLUGS.mk11]: 'MK11',
  [VIDEOGAME_SLUGS.mvc3]: 'MvC3',
  [VIDEOGAME_SLUGS.samsho]: 'SamSho',
  [VIDEOGAME_SLUGS.sc5]: 'SCV',
  [VIDEOGAME_SLUGS.sc6]: 'SCVI',
  [VIDEOGAME_SLUGS.sg]: 'SG',
  [VIDEOGAME_SLUGS.sf4]: 'SF4',
  [VIDEOGAME_SLUGS.sf5]: 'SFV',
  [VIDEOGAME_SLUGS.t6]: 'T6',
  [VIDEOGAME_SLUGS.t7]: 'T7',
  [VIDEOGAME_SLUGS.ttt]: 'TTT',
  [VIDEOGAME_SLUGS.ttt2]: 'TTT2',
  [VIDEOGAME_SLUGS.gbvs]: 'GBVS',
  [VIDEOGAME_SLUGS.sfxt]: 'SFxT',
  [VIDEOGAME_SLUGS.uni]: 'UNI',
})

export const VISIBLE_VIDEOGAMES = [
  // These videogames are visible
  VIDEOGAME_SLUGS.dbfz,
  VIDEOGAME_SLUGS.ggs,
  VIDEOGAME_SLUGS.mk11,
  VIDEOGAME_SLUGS.sg,
  VIDEOGAME_SLUGS.sc6,
  VIDEOGAME_SLUGS.sf5,
  VIDEOGAME_SLUGS.t7,
]

export const HIDDEN_VIDEOGAMES = [
  // hidden by default
  VIDEOGAME_SLUGS.gbvs,
  VIDEOGAME_SLUGS.sf4,
]
