import uniq from 'lodash/uniq'
import omit from 'lodash/omit'
import { useMutation, useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import React from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { Button, Card, Colors, Modal, Portal, Text } from 'react-native-paper'
import { RouteComponentProps } from 'react-router'
import useCurrentUser from '../state/useCurrentUser'
import * as SaveMutation from './__generated__/SaveDuplicatePlayer'
import * as Query from './__generated__/PossibleDuplicatePlayer'
import Loading from '../Loading'
import GlobalError from '../Notifications/GlobalError'
import { useNetwork } from '../state/useNetwork'
import { ReindexDuplicatePlayers } from './__generated__/ReindexDuplicatePlayers'
import { isDevelopmentMode } from '../dev/devMode'
import { useNotification } from '../Notifications/useNotification'
import { NotificationMessage } from '../Notifications/NotificationMessage'
import { QueriedPlayerProfile } from './PlayerProfile'

const styles = StyleSheet.create({
  helpModal: {
    backgroundColor: 'white',
    paddingHorizontal: 0,
    paddingVertical: 20,
    marginHorizontal: 10,
    marginVertical: 50,
    maxHeight: '90%',
  },
  playerProfile: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },

  playerRow: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
  playerRowDetails: {
    marginLeft: 10,
    alignSelf: 'center',
  },
  rowButton: {
    marginHorizontal: 10,
    marginVertical: 20,
  },

  playerTeamHandleView: {
    flexDirection: 'row',
  },
  playerView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  playerTeam: {},
  playerName: {},
  playerHandle: {
    fontWeight: 'bold',
  },

  targetCard: {
    borderColor: Colors.teal300,
    borderWidth: 2,
    marginTop: 10,
    marginBottom: 20,
  },
  possibilities: {
    marginTop: 10,
  },
  duplicateActions: {
    padding: 10,
    justifyContent: 'flex-end',
  },
  playerIdentifierView: {
    marginVertical: 10,
  },
  results: {
    marginVertical: 20,
  },

  actions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignSelf: 'center',
  },
  choiceButton: {
    marginRight: 5,
  },
})

const POSSIBLE_DUPLICATE_PLAYER = gql`
  query PossibleDuplicatePlayer($id: ID) {
    possibleDuplicatePlayer(id: $id) {
      slug
      playerIdentifierId
      handles
      names
      ids
      score
    }
  }
`
const SAVE_DUPLICATE_PLAYER = gql`
  mutation SaveDuplicatePlayer($decisions: [PlayerSimilarityDecision!]!) {
    saveDuplicatePlayer(decisions: $decisions)
  }
`

const REINDEX_DUPLICATE_PLAYERS = gql`
  mutation ReindexDuplicatePlayers {
    reindexDuplicatePlayers
  }
`

// const PlayerText: React.FC<{ team?: string | null; handle: string | null; name?: string | null }> = ({
//   team,
//   handle,
//   name,
// }) => {
//   return (
//     <View style={styles.playerView}>
//       <View style={styles.playerTeamHandleView}>
//         {team && <Text style={styles.playerTeam}>{handle} | </Text>}
//         <Text style={styles.playerHandle}>{handle}</Text>
//       </View>
//       {name && <Text style={[styles.playerName]}> {name}</Text>}
//     </View>
//   )
// }

const PlayerIdentifierText: React.FC<{ children: string[] }> = ({ children }) => {
  return <Text numberOfLines={3}>{children.join(', ')}</Text>
}

const SearchedPlayer: React.FC<{ handles?: string[]; names?: string[] }> = props => {
  const { handles, names } = props
  return (
    <View style={styles.playerIdentifierView}>
      <PlayerIdentifierText>{uniq(handles)}</PlayerIdentifierText>
      <PlayerIdentifierText>{uniq(names)}</PlayerIdentifierText>
    </View>
  )
}

interface Params {
  id: string
}

const DUPLICATE_STATES: { type: DuplicateStateType; displayText: string; color1: string; color2: string }[] = [
  {
    type: 'match',
    displayText: 'Is Duplicate',
    color1: Colors.green700,
    color2: Colors.green300,
  },
  {
    type: 'notMatch',
    displayText: 'Unrelated',
    color1: Colors.red700,
    color2: Colors.red300,
  },
  {
    type: 'skip',
    displayText: 'Not Sure',
    color1: Colors.grey700,
    color2: Colors.grey700,
  },
]

type DuplicateStateType = 'match' | 'notMatch' | 'skip'

interface ChoicesState {
  [id: string]: DuplicateStateType
}

export const DuplicatePlayer: React.FC<RouteComponentProps<Params>> = ({ match }) => {
  const { id } = match.params
  const { notification, setError, setNotification } = useNotification()
  const [choices, setChoices] = React.useState<ChoicesState>({})

  const { isAdmin } = useCurrentUser()
  const isDev = isDevelopmentMode()
  const { handleNetworkError, handleNetworkSuccess } = useNetwork()

  // modal to inspect the possible duplicate player
  const [currentPlayer, setCurrentPlayer] = React.useState('')
  const hideModal = () => setCurrentPlayer('')

  const { data, loading: isQuerying, refetch } = useQuery<
    Query.PossibleDuplicatePlayer,
    Query.PossibleDuplicatePlayerVariables
  >(POSSIBLE_DUPLICATE_PLAYER, {
    notifyOnNetworkStatusChange: true,
    variables: {
      id,
    },
    errorPolicy: 'none',
    fetchPolicy: 'no-cache', // Need this otherwise the cached settings miss data pre-navigation
    onCompleted() {
      handleNetworkSuccess()
    },
    onError(err) {
      handleNetworkError(err)
    },
  })

  const [mutate, { loading: isMutating }] = useMutation<
    SaveMutation.SaveDuplicatePlayer,
    SaveMutation.SaveDuplicatePlayerVariables
  >(SAVE_DUPLICATE_PLAYER, {
    errorPolicy: 'none',
    awaitRefetchQueries: true,
    onCompleted() {
      handleNetworkSuccess()
    },
    onError(err) {
      handleNetworkError(err)
    },
  })

  const [reindex, { loading: isReindexing }] = useMutation<ReindexDuplicatePlayers>(REINDEX_DUPLICATE_PLAYERS, {
    errorPolicy: 'none',
    awaitRefetchQueries: true,
    onCompleted() {
      handleNetworkSuccess()
    },
    onError(err) {
      handleNetworkError(err)
    },
  })

  const loading = isQuerying || isMutating || isReindexing

  if (!isAdmin && !isDev) {
    return <Text>Page not available</Text>
  }

  if (isQuerying) {
    return <Loading />
  }

  const submit = (ignoreAndContinue = false) => {
    if (!data?.possibleDuplicatePlayer) {
      setError('No duplicate data available')
    } else {
      const decisions = data.possibleDuplicatePlayer.map(item => {
        const { slug } = item
        let isMatch = null
        if (choices[slug] === 'match') {
          isMatch = true
        } else if (choices[slug] === 'notMatch') {
          isMatch = false
        }
        return {
          slug,
          isMatch: ignoreAndContinue ? null : isMatch,
          data: omit(item, '__typename'),
        }
      })
      return mutate({
        variables: {
          decisions,
        },
      })
        .then(() => setNotification('Success!', 'success'))
        .then(() => refetch().catch(err => setError(err)))
    }
  }

  const refetchButton = (
    <View style={styles.rowButton}>
      <Button
        icon="refresh-circle"
        color={Colors.blue700}
        mode="outlined"
        disabled={loading}
        onPress={() => refetch().catch(err => setError(err))}
      >
        Refresh
      </Button>
    </View>
  )

  const skipButton = !id && (
    <Button
      style={styles.rowButton}
      color={Colors.grey700}
      mode="contained"
      disabled={loading || !data?.possibleDuplicatePlayer.length}
      onPress={() => submit(true)}
    >
      Skip
    </Button>
  )

  return (
    <View style={{ alignItems: 'center' }}>
      <View>
        <View style={styles.actions}>
          {skipButton}
          {refetchButton}
          {isDev && (
            <View style={styles.rowButton}>
              <Button
                icon="refresh-circle"
                mode="contained"
                disabled={loading}
                color={Colors.red700}
                onPress={() => {
                  reindex().catch(err => setError(err))
                }}
              >
                Reindex
              </Button>
            </View>
          )}
        </View>
        {!data?.possibleDuplicatePlayer.length ? (
          <Text style={styles.results} numberOfLines={2}>
            No data.
          </Text>
        ) : (
          <View style={styles.results}>
            <GlobalError marginBottom={10} />
            <NotificationMessage {...notification} />
            <Text>Try to find duplicates for this player:</Text>
            <Card elevation={5} style={styles.targetCard}>
              <Card.Content>
                <View style={styles.playerRow}>
                  <SearchedPlayer {...data.possibleDuplicatePlayer[0]} />
                  <Button
                    disabled={loading}
                    style={styles.playerRowDetails}
                    onPress={() => setCurrentPlayer(data.possibleDuplicatePlayer[0].slug)}
                    color={Colors.blue700}
                    mode="outlined"
                  >
                    Details
                  </Button>
                </View>
              </Card.Content>
            </Card>
            <Text>Possible duplicates:</Text>
            {data.possibleDuplicatePlayer.slice(1).map(item => {
              return (
                <React.Fragment key={item.playerIdentifierId}>
                  <Card elevation={4} style={styles.possibilities}>
                    <Card.Content>
                      <View style={styles.playerRow}>
                        <SearchedPlayer {...item} />
                        <Button
                          disabled={loading}
                          style={styles.playerRowDetails}
                          onPress={() => setCurrentPlayer(item.slug)}
                          color={Colors.blue700}
                          mode="outlined"
                        >
                          <Text>({Math.trunc(item.score * 100) / 100}) </Text>
                          Details
                        </Button>
                      </View>
                    </Card.Content>
                    <Card.Actions style={styles.duplicateActions}>
                      {DUPLICATE_STATES.map(({ type, displayText, color1, color2 }) => {
                        const chosen =
                          choices[item.slug] === type ||
                          (!['match', 'notMatch'].includes(choices[item.slug]) && type === 'skip')
                        return (
                          <Button
                            disabled={isMutating}
                            key={type}
                            style={styles.choiceButton}
                            onPress={() => setChoices({ ...choices, [item.slug]: type })}
                            color={chosen ? color1 : color2}
                            mode={chosen ? 'contained' : 'outlined'}
                          >
                            {displayText}
                          </Button>
                        )
                      })}
                    </Card.Actions>
                  </Card>
                </React.Fragment>
              )
            })}
          </View>
        )}
      </View>

      <GlobalError marginBottom={10} />
      <NotificationMessage {...notification} />

      <View style={styles.actions}>
        {skipButton}
        <Button
          style={styles.rowButton}
          color={Colors.green700}
          mode="contained"
          disabled={loading || !data?.possibleDuplicatePlayer.length}
          onPress={() => submit()}
        >
          Save{id ? '' : ' & Continue'}
        </Button>
      </View>

      <Portal>
        <Modal visible={!!currentPlayer} onDismiss={hideModal} contentContainerStyle={styles.helpModal}>
          <ScrollView contentContainerStyle={styles.playerProfile}>
            <QueriedPlayerProfile id={currentPlayer} />
          </ScrollView>
        </Modal>
      </Portal>
    </View>
  )
}
